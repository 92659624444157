/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useScrollProgress } from '../hooks/useScrollProgress';

import { changePage } from '../reducers/graph';
import { RootState } from '../types/graph';
import { pages } from '../util/graph';
import { Typography } from '@mui/material';

interface ConnectorProps {
  active?: boolean;
  completed?: boolean;
  first?: boolean;
  last?: boolean;
  vertical?: boolean;
  bookend?: boolean;
}

const HorizontalConnector = ({
  active,
  completed,
  first,
  last,
}: ConnectorProps) => (
  <div
    css={(theme) => ({
      width: first || last ? 'calc(50% - 4px)' : 'calc(100% - 8px)',
      backgroundColor: completed ? theme.palette.primary.main : '#F5F5F5',
      height: 5,
      position: 'absolute',
      left: first ? 0 : 'calc(50% + 4px)',
      right: last ? 0 : 'calc(150% - 4px)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    })}
  >
    {active && <div className="active" />}
  </div>
);

const VerticalConnector = ({
  active,
  completed,
  first,
  last,
  bookend,
}: ConnectorProps) => (
  <div
    css={(theme) => ({
      height: bookend
        ? '100%'
        : first || last
        ? 'calc(50% - 4px)'
        : 'calc(100% - 8px)',
      backgroundColor: completed ? theme.palette.primary.main : '#F5F5F5',
      width: 5,
      position: 'absolute',
      top: first ? 0 : 'calc(50% + 4.5px)',
      bottom: last ? 4 : 'calc(150% - 4px)',
      left: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    })}
  >
    {active && <div className="active" />}
  </div>
);

interface BookendProps {
  completed?: boolean;
}

const Bookend = ({ completed }: BookendProps) => (
  <div css={{ position: 'relative', flex: '1 1 0', marginLeft: 24 }}>
    <VerticalConnector vertical bookend first completed={completed} />
  </div>
);

interface StepIconProps {
  active?: boolean;
  completed?: boolean;
  onClick?: () => void;
}

const StepIcon = ({ active, completed, onClick }: StepIconProps) => (
  <div
    css={(theme) => ({
      width: 9,
      height: 9,
      flexShrink: 0,
      borderRadius: 9,
      backgroundColor:
        active || completed ? theme.palette.primary.main : '#F5F5F5',
    })}
    onClick={onClick}
  />
);

interface StepLabelProps {
  label: string;
  active?: boolean;
  completed?: boolean;
  navigable?: boolean;
  onClick?: () => void;
}

const StepLabel = ({
  label,
  active,
  completed,
  navigable,
  onClick,
}: StepLabelProps) => (
  <Typography
    sx={(theme) => ({
      fontWeight: 600,
      color: active
        ? theme.palette.secondary.main
        : completed || navigable
        ? theme.palette.primary.main
        : theme.palette.gray,
      mr: 1,
      userSelect: 'none',
    })}
    onClick={onClick}
  >
    {label}
  </Typography>
);

interface StepProps {
  label: string;
  step: number;
  active: boolean;
  completed: boolean;
  first: boolean;
  last: boolean;
  navigable: boolean;
  vertical?: boolean;
}

const Step = ({
  label,
  step,
  active,
  completed,
  first,
  last,
  navigable,
  vertical,
}: StepProps) => {
  const dispatch = useDispatch();

  const goToPage = useCallback(() => {
    dispatch(changePage(label));
    window.scrollTo(0, 0);
  }, [dispatch, label]);

  const Connector = vertical ? VerticalConnector : HorizontalConnector;

  return (
    <div
      css={{
        position: 'relative',
        flex: '1 1 0',
        minHeight: vertical ? 36 : undefined,
        width: vertical ? '100%' : 0,
        display: 'flex',
        flexDirection: vertical ? 'row' : 'column',
        justifyContent: vertical ? 'flex-start' : 'space-evenly',
        alignItems: 'center',
        gap: 12,
        marginTop: vertical ? 0 : 6,
        cursor: navigable ? 'pointer' : undefined,
      }}
    >
      {first && <Connector first completed />}
      <StepIcon
        active={active}
        completed={vertical || completed}
        onClick={navigable ? goToPage : undefined}
      />
      {vertical && (
        <StepLabel
          label={label}
          active={active}
          completed={completed}
          navigable={navigable}
          onClick={navigable ? goToPage : undefined}
        />
      )}
      <Connector
        active={active}
        completed={vertical || completed}
        last={last}
      />
    </div>
  );
};

interface StepperProps {
  activeStep: number;
  steps: string[];
  maxPage: number;
  vertical?: boolean;
}

const Stepper = ({ activeStep, steps, maxPage, vertical }: StepperProps) => {
  const theme = useTheme();
  const [scrollProgress] = useScrollProgress();

  return (
    <div
      css={{
        zIndex: 2,
        position: vertical ? undefined : 'absolute',
        bottom: vertical ? undefined : -2,
        width: vertical ? undefined : '100%',
        height: vertical ? '50%' : undefined,
        marginLeft: vertical ? 24 : undefined,
        display: 'flex',
        flexDirection: vertical ? 'column' : 'row',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
        '.active': {
          backgroundColor: theme.palette.primary.main,
          zIndex: 12,
          [vertical ? 'height' : 'width']: `${scrollProgress}%`,
        },
      }}
    >
      {steps.map((step, i) => (
        <Step
          key={step}
          label={step}
          step={i}
          active={i === activeStep}
          completed={i < activeStep}
          navigable={i <= maxPage}
          first={i === 0}
          last={i === steps.length - 1}
          vertical={vertical}
        />
      ))}
    </div>
  );
};

export interface ProgressBarProps {
  allCompleted?: boolean;
  vertical?: boolean;
}

export const ProgressBar = ({ allCompleted, vertical }: ProgressBarProps) => {
  const navigate = useNavigate();

  const currentPage = useSelector(
    (state: RootState) => state.graph.currentPage
  );

  const maxPage = useSelector(
    (state: RootState) => state.questionnaire.maxPage
  );

  useEffect(() => {
    if (pages.indexOf(currentPage) === pages.length) navigate('/recap');
  }, [currentPage, navigate]);

  const stepper = (
    <Stepper
      activeStep={allCompleted ? Infinity : pages.indexOf(currentPage)}
      steps={pages}
      maxPage={allCompleted ? -1 : maxPage}
      vertical={vertical}
    />
  );

  return vertical ? (
    <div
      css={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Bookend completed />
      {stepper}
      <Bookend completed />
    </div>
  ) : (
    stepper
  );
};

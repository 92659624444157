import { ConsultationMotive } from '../types/global';
import { useQuery } from 'react-query';
import { client } from '../util/client';

const kbReportRegex = /(^C.+)-.+\+ (.+)/;

// Returns reportId (C.01.01.01) if the report is a consultation motive, otherwise returns null
export const getReportIdIfConsultationMotive = (report: string) =>
  report.match(kbReportRegex)?.[1];

export const useConsultationMotives = (
  report: string[]
): ConsultationMotive['id'][] => {
  const { data: consultationMotives } = useQuery<ConsultationMotive[]>(
    'consultationMotives',
    async () =>
      client('/consultationMotives').then((response) => response?.data)
  );

  if (!consultationMotives) return [];

  return report
    .map(getReportIdIfConsultationMotive)
    .map((rId) => consultationMotives.find((m) => m.kbId && m.kbId === rId)?.id)
    .filter((m) => m !== undefined) as number[];
};

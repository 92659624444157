const config = {
  apiUrl: process.env.REACT_APP_API_URL ?? 'http://localhost:3000',
  kbUrl: process.env.REACT_APP_KB_URL ?? 'http://localhost:4001',
  kbEndpoint: process.env.REACT_APP_KB_ENDPOINT ?? '/graphql',
  kbVersion: isNaN(Number(process.env.REACT_APP_KB_VERSION))
    ? undefined
    : Number(process.env.REACT_APP_KB_VERSION),
  appVersion: process.env.REACT_APP_APP_VERSION ?? 'development',
  env: {
    production: Boolean(
      process.env.NODE_ENV === 'production' && !process.env.REACT_APP_STAGING
    ),
    staging: Boolean(process.env.REACT_APP_STAGING),
    dev: Boolean(process.env.NODE_ENV === 'development'),
    demo: Boolean(process.env.REACT_APP_DEMO),
  },
  debug: {
    evaluate: Boolean(process.env.REACT_APP_DEBUG_EVALUATE),
    after: Boolean(process.env.REACT_APP_DEBUG_AFTER),
    panel: Boolean(process.env.REACT_APP_DEBUG_PANEL),
  },
};

export default config;

/** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom';

import { Chip, IconButton } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { useTheme } from '@emotion/react';

import { ReactComponent as Logo } from '../assets/HeaderLogo.svg';
import config from '../config';
import { HeaderContentProps } from './Header';

export const DefaultHeader = ({ menu, onOpenMenu }: HeaderContentProps) => {
  const theme = useTheme();

  return (
    <div
      css={{
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'baseline',
        paddingRight: 22,
        paddingLeft: 22,
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Link
        to="/"
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 10,
          textDecoration: 'none',
        }}
      >
        <Logo css={{ width: 160 }} />
        {config.env.demo && (
          <Chip size="small" color="secondary" label="DEMO" />
        )}
      </Link>
      <IconButton
        css={{ color: theme.palette.white }}
        size="large"
        onClick={onOpenMenu}
      >
        <HelpOutlineOutlinedIcon />
      </IconButton>
      {menu}
    </div>
  );
};

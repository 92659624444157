/** @jsxImportSource @emotion/react */
import { ChatBubble } from './ChatBubble';

interface SectionIntroductionProps {
  text?: string;
}

export const SectionIntroduction = ({ text }: SectionIntroductionProps) =>
  text ? (
    <div
      css={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: 20,
        borderBottom: `0.25px solid ${theme.palette.gray}`,
        width: 'calc(100% - 40px)',
      })}
    >
      <ChatBubble assistant>{text}</ChatBubble>
    </div>
  ) : null;

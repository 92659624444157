/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react';
import { Outlet } from 'react-router-dom';

import { useBrowserThemeColor } from '../hooks/useBrowserThemeColor';

import { Header } from './Header';
import { Menu } from './Menu';
import { ProgressBar } from './ProgressBar';
import { Copyright } from './Copyright';
import { ScrollProvider } from '../context/scrollContext';

interface MainLayoutProps {
  questionnaire?: boolean;
  children?: React.ReactNode;
}

export const MainLayout = ({
  questionnaire = false,
  children = <Outlet />,
}: MainLayoutProps) => {
  const theme = useTheme();

  useBrowserThemeColor(
    questionnaire ? theme.palette.white : theme.palette.primary.main
  );

  return (
    <ScrollProvider>
      <div
        css={{
          width: '100vw',
          height: '100vh',
          '@media not (pointer: fine)': {
            height: window?.innerHeight ?? '100vh', // use innerHeight on mobile, especially for Android devices
          },
          maxHeight: '-webkit-fill-available',
          display: 'grid',
          gridTemplateAreas: `"header header menubar"
        "sidebar content menubar"`,
          gridTemplateRows: '60px 1fr',
          gridTemplateColumns: questionnaire ? '22% 1fr 50px' : '22% 1fr 0px',
          [theme.breakpoints.down('md')]: {
            gridTemplateRows: '80px 1fr',
            gridTemplateColumns: '0px 1fr 0px',
          },
          overflow: 'hidden !important',
        }}
      >
        <header
          css={{
            gridArea: 'header',
            width: '100vw',
            position: 'fixed',
            height: 60,
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
            [theme.breakpoints.down('md')]: {
              height: 80,
              boxShadow: 'none',
            },
            top: 0,
            left: 0,
            zIndex: 3,
          }}
        >
          <Header questionnaire={questionnaire} />
        </header>
        <aside
          css={{
            gridArea: 'sidebar',
            backgroundColor: theme.palette.lightGray,
            boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.1)',
            overflowY: 'auto',
            zIndex: 2,
          }}
        >
          {questionnaire && (
            <>
              <ProgressBar vertical />
              <Copyright />
            </>
          )}
        </aside>
        <main
          css={{
            gridArea: 'content',
            width: '100%',
            [theme.breakpoints.down('md')]: {
              height: `calc(${window?.innerHeight} - 60px)`, // fix overscroll on input blur on iOS
            },
            overflowY: 'auto',
            overscrollBehavior: 'contain',
          }}
        >
          {children}
        </main>
        <aside
          css={{
            gridArea: 'menubar',
            backgroundColor: theme.palette.primary.main,
            zIndex: 4,
            height: '100%',
            width: '100%',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
            '&:not(:hover)': {
              '.hoverMenu': {
                display: 'none',
              },
            },
            ':before': {
              content: '""',
              position: 'absolute',
              right: 50,
              top: 0,
              width: 12,
              height: '100%',
            },
          }}
        >
          <div
            className="hoverMenu"
            css={{
              position: 'fixed',
              right: 0,
              width: 280,
              height: '100%',
              zIndex: 4,
              transition: 'visibility 0.5s ease-in-out',
              ':after': {
                content: '""',
                position: 'absolute',
                left: -12,
                top: 0,
                width: 12,
                height: '100%',
                zIndex: 4,
              },
            }}
          >
            <Menu />
          </div>
          <Menu compact />
        </aside>
      </div>
    </ScrollProvider>
  );
};

import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface MoreButtonProps {
  label: string;
  onChange: (value: boolean) => void;
}

export const MoreButton = ({
  label = 'plus de réponses',
  onChange,
}: MoreButtonProps) => (
  <Button
    variant="text"
    size="large"
    sx={{
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: 3,
      paddingTop: 1.5,
      paddingBottom: 0,
      textTransform: 'none',
      fontStyle: 'italic',
    }}
    onClick={() => onChange(true)}
    startIcon={<ExpandMoreIcon />}
    endIcon={<ExpandMoreIcon />}
  >
    {label}
  </Button>
);

/** @jsxImportSource @emotion/react */
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from './Button';

interface ResponseButtonProps {
  label: string;
  selected: boolean;
  multiselect?: boolean;
  onChange: (value: boolean) => void;
  freeform?: boolean;
}

export const ResponseButton = ({
  label,
  selected,
  onChange,
  multiselect,
  freeform,
  ...props
}: ResponseButtonProps) => (
  <Button filled={selected} onClick={() => onChange(!selected)} {...props}>
    {multiselect &&
      (selected ? (
        <CheckBoxOutlinedIcon css={{ height: 18 }} />
      ) : (
        <CheckBoxOutlineBlankOutlinedIcon css={{ height: 18 }} />
      ))}
    <div
      css={{
        paddingTop: 12,
        paddingBottom: 12,
      }}
    >
      {label}
    </div>
    {freeform && selected && <EditIcon css={{ height: 18 }} />}
  </Button>
);

/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { Response } from './Response';

import { Value, ViewableResponse } from '../types/global';
import { recordResponses } from '../reducers/graph';
import { RootState, UserResponseStore } from '../types/graph';
import { useTheme } from '@emotion/react';
import { QuestionLoadingIndicator } from './QuestionLoadingIndicator';
import clsx from 'clsx';

interface ExpandButtonProps {
  onExpand: () => void;
}

const ExpandButton = ({ onExpand }: ExpandButtonProps) => (
  <div
    css={(theme) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      marginTop: 8,
    })}
    onClick={onExpand}
  >
    <div
      css={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        gap: 16,
        '.line': {
          flexGrow: 1,
          borderTop: `1.5px solid ${theme.palette.secondary.main}`,
        },
      })}
    >
      <div className="line" />
      <Typography variant="button">Plus de réponses</Typography>
      <div className="line" />
    </div>
    <ArrowDownwardIcon />
  </div>
);

interface QuestionProps {
  extId: string;
  currentPage: string;
  loading?: boolean;
  inactive?: boolean;
  error?: boolean;
  resetErrors: () => void;
}

const expandedLimit = 5;

export const Question = ({
  extId,
  currentPage,
  loading,
  error,
  inactive,
  resetErrors,
}: QuestionProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const containerRef = useRef(null);

  const question = useSelector((state: RootState) => state.kb.questions[extId]);

  const userResponses = useSelector(
    (state: RootState) => state.userResponses[extId] ?? {}
  );

  const [values, setValues] = useState<UserResponseStore>(userResponses);
  const [expanded, setExpanded] = useState(false);

  if (!question) return null;
  const { responses } = question;

  const exclusiveResponses =
    responses.filter((r) => r.exclusive).map((r) => r.extId) ?? [];

  const demo = false;

  const createChangeHandler =
    (response: ViewableResponse) => (value: Value) => {
      resetErrors();
      if (value === true) {
        const nextValues = { ...values };
        if (question.multipleChoice === false || response.exclusive) {
          Object.keys(values).forEach((key) => {
            if (values[key] === true) nextValues[key] = false;
          });
        } else {
          exclusiveResponses.forEach((key) => {
            if (key !== response.extId) nextValues[key] = false;
          });
        }
        nextValues[response.extId] = value;
        setValues(nextValues);

        const toRecord = question.responses
          .filter((r) => Object.keys(nextValues).includes(r.extId))
          .map((r) => ({
            ...r,
            value: { value: nextValues[r.extId], unit: response.unit },
          }));

        dispatch(
          recordResponses({
            questionId: extId,
            currentPage,
            responses: toRecord,
          })
        );
      } else {
        setValues({ ...values, [response.extId]: value });
        dispatch(
          recordResponses({
            questionId: extId,
            currentPage,
            responses: [{ ...response, value: { value, unit: response.unit } }],
          })
        );
      }
    };

  const expandable =
    question.paginateResponses &&
    !expanded &&
    question.responses.length - expandedLimit > 2;

  return (
    <>
      <div
        ref={containerRef}
        css={{
          width: '100%',
          backgroundColor:
            inactive && Object.keys(values).length
              ? theme.palette.backgroundGray
              : undefined,
          transition: 'background-color 0.3s ease-in-out',
          ':not(:first-of-type) > .question:not(.expandable)': {
            '&:after': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: 0,
              height: 0.5,
              width: '20%',
              borderTop: `0.5px solid ${theme.palette.primary.main}`,
            },
          },
        }}
      >
        <Grow style={{ transformOrigin: 'top center' }} in={true} unmountOnExit>
          <div
            id={extId}
            className={clsx('question', expandable && 'expandable')}
            css={{
              position: 'relative',
              marginRight: 24,
              marginLeft: 24,
              paddingTop: 22,
              paddingBottom: expandable ? 0 : 22,
            }}
          >
            <Typography
              sx={(theme) => ({
                color: error ? theme.palette.coral : theme.palette.primary.main,
                fontSize: 14,
                fontWeight: 600,
              })}
            >
              {question.required && (
                <span
                  css={(theme) => ({
                    color: theme.palette.coral,
                  })}
                >
                  {'*\u00A0'}
                </span>
              )}
              {question.label?.replace(' ?', '\u00A0?')}
            </Typography>
            {question.comment && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: 14,
                  fontWeight: 'light',
                  fontStyle: 'italic',
                  color: theme.palette.primary.main,
                }}
              >
                {question.comment}
              </Typography>
            )}
            <div
              css={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                marginTop: 18,
                [theme.breakpoints.up('md')]: {
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  columnGap: 20,
                  '& > *': {
                    flexBasis: responses.length > 3 ? '30%' : undefined,
                    minWidth: 95,
                    minHeight: 0,
                  },
                },
                gap: 8,
              })}
            >
              {(expandable ? responses.slice(0, expandedLimit) : responses).map(
                (response) => (
                  <Response
                    key={response.extId}
                    response={response}
                    onChange={createChangeHandler(response)}
                    value={values[response.extId]}
                    questionLabel={question.label}
                    multiselect={question.multipleChoice}
                    disabled={demo}
                  />
                )
              )}
            </div>
            {expandable && <ExpandButton onExpand={() => setExpanded(true)} />}
          </div>
        </Grow>
      </div>
      {loading && <QuestionLoadingIndicator />}
    </>
  );
};

import { Avatar } from '@mui/material';

import { ReactComponent as ShortLogo } from '../assets/ShortLogo.svg';

interface AssistantAvatarProps {
  size?: number;
}

export const AssistantAvatar = ({ size }: AssistantAvatarProps) => (
  <Avatar
    sx={(theme) => ({
      backgroundColor: theme.palette.secondary.main,
      ...(size ? { height: size, width: size } : {}),
    })}
    alt="Assistant Aldebaran"
  >
    <ShortLogo />
  </Avatar>
);

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../types/graph';

export const useInvalidQuestions = () => {
  const activeQueue = useSelector(
    (state: RootState) => state.questionnaire.activeQueue
  );
  const questions = useSelector((state: RootState) => state.kb.questions);
  const responses = useSelector((state: RootState) => state.userResponses);

  const invalidQuestions = useMemo(
    () =>
      activeQueue.filter(
        (extId) =>
          questions[extId]?.required &&
          (!responses[extId] ||
            Object.values(responses[extId]).every(
              (r) => r === false || r === undefined
            ))
      ),
    [activeQueue, questions, responses]
  );

  return invalidQuestions;
};

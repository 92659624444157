import { useEffect } from 'react';

export const useBrowserThemeColor = (color?: string): void => {
  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name=theme-color]');
    if (metaThemeColor && color) {
      metaThemeColor.setAttribute('content', color);
    }
  }, [color]);
};

import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pick from 'lodash/pick';

import { ALL_QUESTION_FIELDS } from '../fragments/question.fragments';

import { updateQuestions } from '../reducers/graph';
import { useCheckCondition } from './useCheckCondition';
import { useInitialQuestions } from './useInitialQuestions';

import {
  transformAfterEdges,
  transformEdges,
  transformInserts,
  transformUnitEdges,
} from '../util/kb';

import { Increment, Maximum, Minimum, ViewableQuestion } from '../types/global';
import { QuestionsData, Response } from '../types/kb';
import { useVersion } from './useVersion';
import { RootState } from '../types/graph';

const GET_QUESTION_QUERY = gql`
  ${ALL_QUESTION_FIELDS}
  query Question($version: Float!, $extIds: [String]!) {
    questions(where: { version: $version, extId_IN: $extIds }) {
      ...AllQuestionFields
    }
  }
`;

export const useQuestions = () => {
  const dispatch = useDispatch();
  const version = useVersion();

  const check = useCheckCondition();

  useInitialQuestions();

  const extIds = useSelector((state: RootState) => state.questionnaire.queue);
  const currentPage = useSelector(
    (state: RootState) => state.graph.currentPage
  );
  const insertedBy = useSelector(
    (state: RootState) => state.questionnaire.insertedBy
  );
  const pageAssignments = useSelector(
    (state: RootState) => state.questionnaire.pageAssignments
  );

  const { data, loading } = useQuery<QuestionsData>(GET_QUESTION_QUERY, {
    variables: { version, extIds },
  });

  useEffect(() => {
    if (!data?.questions) return;

    const questions: ViewableQuestion[] = data.questions
      .map((question) => ({
        ...question,
        labels: transformEdges(question.labelsConnection),
        comments: transformEdges(question.commentsConnection),
        after: transformAfterEdges(question.afterConnection),
        responses: question.responses.map((r: Response) => ({
          ...r,
          format: r.format ?? question.format,
          insertsQuestions: transformInserts(r.insertsQuestions),
          deletesQuestions: transformInserts(r.deletesQuestions),
          reports: transformEdges(r.reportsConnection),
          labels: transformEdges(r.labelsConnection),
          units: transformUnitEdges(r.unitConnection),
          minimums: transformEdges<Minimum, number>(r.minimumConnection),
          maximums: transformEdges<Maximum, number>(r.maximumConnection),
          increments: transformEdges<Increment, number>(r.incrementConnection),
        })),
      }))
      .map((question) => ({
        ...question,
        label:
          question.labels.filter((l) => check(l.condition))?.[0]?.value ?? null,
        comment: question.comments.filter((c) => check(c.condition))?.[0]
          ?.value,
        after:
          question.after.filter((a) => check(a.condition))?.[0]?.value ??
          insertedBy[question.extId] ??
          null,
        responses: question.responses
          .map((response) => ({
            ...response,
            question: response.question.extId,
            label:
              response.labels.filter((l) => check(l.condition))?.[0]?.value ??
              null,
            unit: pick(response.units.filter((u) => check(u.condition))?.[0], [
              'singular',
              'plural',
            ]),
            minimum: response.minimums.filter((m) => check(m.condition))?.[0]
              ?.value,
            maximum: response.maximums.filter((m) => check(m.condition))?.[0]
              ?.value,
            increment: response.increments.filter((i) =>
              check(i.condition)
            )?.[0]?.value,
          }))
          .filter((r) => r.label !== null)
          .sort((a, b) => a.extId.localeCompare(b.extId)),
      }))
      .filter((q) => q.label !== null);
    dispatch(updateQuestions(questions));
  }, [data, check, dispatch, insertedBy, currentPage, pageAssignments]);

  return { loading };
};

import { useSelector } from 'react-redux';

import { RootState } from '../types/graph';
import { evaluate } from '../util/graph';

export const useEvaluate = () => {
  const values = useSelector((state: RootState) => state.graph.values);
  const formulas = useSelector((state: RootState) => state.kb.formulas);

  return (clause: string) => evaluate(values, formulas, clause);
};

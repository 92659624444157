import { GenericConditionalValue, Unit } from '../types/global';
import { AfterConnection, Connection, InsertableQuestion } from '../types/kb';

export const transformEdges = <
  T extends GenericConditionalValue<S>,
  S = string
>(
  connection: Connection<T>
) =>
  connection.edges.map((e) => ({
    id: e.node.id,
    value: e.node.value,
    condition: e.condition,
  }));

export const transformAfterEdges = (connection: AfterConnection) =>
  connection.edges.map((e) => ({
    id: e.node.extId,
    value: e.node.extId,
    condition: e.condition,
  }));

export const transformUnitEdges = (connection: Connection<Unit>) =>
  connection.edges.map((e) => ({
    ...e.node,
    condition: e.condition,
  }));

export const transformInserts = (insertions: InsertableQuestion[]) =>
  insertions.map((q: InsertableQuestion) => ({
    extId: q.extId,
    after: transformAfterEdges(q.afterConnection),
  }));

import { gql, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { insertQuestions } from '../reducers/graph';
import { RootState } from '../types/graph';
import { InitialQuestionData } from '../types/kb';
import { transformAfterEdges } from '../util/kb';
import { useVersion } from './useVersion';

const GET_INITIAL_QUESTIONS_QUERY = gql`
  query InitialQuestions($version: Float!) {
    initialQuestions(version: $version) {
      extId
      afterConnection {
        edges {
          condition
          node {
            extId
          }
        }
      }
    }
  }
`;

export const useInitialQuestions = () => {
  const dispatch = useDispatch();
  const version = useVersion();
  const queue = useSelector((state: RootState) => state.questionnaire.queue);

  const queueEmpty = queue.length <= 1;

  useQuery<InitialQuestionData>(GET_INITIAL_QUESTIONS_QUERY, {
    variables: { queueEmpty, version },
    onCompleted: (data) => {
      if (data?.initialQuestions)
        dispatch(
          insertQuestions(
            data.initialQuestions.map((q) => ({
              extId: q.extId,
              after: transformAfterEdges(q.afterConnection),
            }))
          )
        );
    },
  });
};

/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';

import { Provider } from 'react-redux';
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from '@mui/material';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { Routes, Route } from 'react-router-dom';

import { generateStore } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { theme as appTheme } from './util/theme';

import { useQuestions } from './hooks/useQuestions';
import { useFormulas } from './hooks/useFormulas';

import { Intro } from './pages/Intro';
import { Questionnaire } from './pages/Questionnaire';
import { PracticeLanding } from './pages/PracticeLanding';

import { SubmissionPage } from './pages/SubmissionPage';
import { Debug } from './pages/Debug';

import config from './config';

import axios from 'axios';
import { PersistProvider } from './context/persistContext';
import { VersionProvider } from './context/versionContext';
import { useReferrer } from './hooks/useReferrer';
import { CheckIn } from './pages/CheckIn';
import { ProfessionalLanding } from './pages/ProfessionalLanding';
import { MainLayout } from './components/MainLayout';
import { Home } from './pages/Home';
import { QuestionLoadingIndicator } from './components/QuestionLoadingIndicator';
import { Confirmation } from './pages/Confirmation';

const theme = createTheme(appTheme);

const Root = () => {
  const { loading } = useQuestions();
  useFormulas();
  useReferrer();

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/intro" element={<Intro />} />
        <Route path="/:slug" element={<PracticeLanding />} />
        <Route path="/practice" element={<PracticeLanding />} />
        <Route path="/professional/:slug?" element={<ProfessionalLanding />} />
        <Route path="/checkin" element={<CheckIn />} />
        <Route path="/confirmation" element={<Confirmation />} />
      </Route>
      <Route element={<MainLayout questionnaire />}>
        <Route
          index
          path="/questionnaire"
          element={
            <Questionnaire
              questionnaireLoading={loading}
              submissionLoading={false}
            />
          }
        />
        <Route
          path="/submit"
          element={<SubmissionPage questionnaireLoading={loading} />}
        />
        {config.debug.panel && <Route path="/debug" element={<Debug />} />}
      </Route>
      <Route index element={<Home />} />
    </Routes>
  );
};

const queryClient = new QueryClient();

export const App = () => {
  const envVersion = config.kbVersion;
  const [version, setVersion] = useState<number | undefined>(envVersion);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    if (!version)
      axios
        .get(process.env.REACT_APP_KB_URL + '/version')
        .then((res) => setVersion(Number(res.data)))
        .catch((err) => {
          console.error(err);
          setError(err);
        });
  }, [version]);

  if (version && !isNaN(version)) {
    const { store, persistor } = generateStore(version);
    return (
      <Provider store={store}>
        <EmotionThemeProvider theme={theme}>
          <MaterialThemeProvider theme={theme}>
            <PersistGate loading={null} persistor={persistor}>
              <PersistProvider value={persistor}>
                <VersionProvider value={version}>
                  <QueryClientProvider client={queryClient}>
                    <Root />
                  </QueryClientProvider>
                </VersionProvider>
              </PersistProvider>
            </PersistGate>
          </MaterialThemeProvider>
        </EmotionThemeProvider>
      </Provider>
    );
  }
  return (
    <QuestionLoadingIndicator
      fullscreen
      error={error ? 'Une erreur est survenue' : undefined}
    />
  );
};

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { pulseFadeInAndOut } from '../styles/animations';
import { AssistantAvatar } from './AssistantAvatar';
import { Typography } from '@mui/material';

interface QuestionLoadingIndicatorProps {
  fullscreen?: boolean;
  stretch?: boolean;
  error?: string;
}

export const QuestionLoadingIndicator = ({
  fullscreen,
  stretch,
  error,
}: QuestionLoadingIndicatorProps) => (
  <div
    css={{
      width: '100%',
      minHeight: '100%',
      height: fullscreen ? '100vh' : undefined,
      flexGrow: stretch ? 1 : undefined,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 12,
    }}
  >
    <div
      css={
        error
          ? undefined
          : css`
              opacity: 0;
              animation: ${pulseFadeInAndOut} 1.5s ease-in-out infinite;
            `
      }
    >
      <AssistantAvatar />
    </div>
    {error && <Typography variant="caption">{error}</Typography>}
  </div>
);

/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from '@mui/material';
import { useSelector } from 'react-redux';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { DialogTitleWithClose } from '../components/DialogTitleWithClose';
import { usePurgeState } from '../hooks/usePurgeState';
import { RootState } from '../types/graph';
import Button from '@mui/material/Button';

interface HelpDialogProps {
  open: boolean;
  onClose: () => void;
}

export const HelpDialog = ({ open, onClose }: HelpDialogProps) => {
  const purgeState = usePurgeState();
  const practiceSlug = useSelector(
    (state: RootState) => state.consultation?.practice?.slug
  );

  const contact = useCallback(() => {
    window.location.href =
      'mailto:support@aldebaran.care?subject=Assistance%20questionnaire';
    onClose();
  }, [onClose]);

  const reset = useCallback(() => {
    purgeState();
    window.location.href = practiceSlug ? `/${practiceSlug}` : '/';
  }, [practiceSlug, purgeState]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitleWithClose onClose={onClose}>Assistance</DialogTitleWithClose>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Si vous rencontrez des difficultés à remplir votre questionnaire, ou
          si vous avez simplement des questions pour nous, n'hésitez pas à nous
          contacter.
        </DialogContentText>
        <DialogActions sx={{ justifyContent: 'space-between', pl: 0 }}>
          <IconButton onClick={reset}>
            <RestartAltIcon color="disabled" />
          </IconButton>
          <Button variant="contained" onClick={contact}>
            Nous contacter
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

import { useMutation } from 'react-query';
import { client } from '../util/client';

export interface RequestDemoParams {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  referrer?: string;
}

export const useRequestDemo = ({ onSuccess }: { onSuccess: () => void }) =>
  useMutation(
    async (params: RequestDemoParams) => {
      const response = await client.post('/demoRequest', params);
      return response.data;
    },
    {
      mutationKey: 'requestDemo',
      onSuccess,
    }
  );

/** @jsxImportSource @emotion/react */
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../types/graph';

export const Home = () => {
  const consultation = useSelector((state: RootState) => state.consultation);

  if (consultation.professional) {
    return <Navigate to="/questionnaire" />;
  } else if (consultation.practice) {
    return <Navigate to={`/${consultation.practice.slug}`} />;
  }
  return null;
};

/** @jsxImportSource @emotion/react */
import { InputHTMLAttributes, ReactNode } from 'react';

export type InputOnBlurEvent = React.FocusEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  error?: boolean;
  multiline?: boolean;
  rows?: number;
  helperText?: string;
  startIcon?: ReactNode;
  endItem?: ReactNode;
  halfWidth?: boolean;
  onBlur: (e: InputOnBlurEvent) => void;
}

export const Input = ({
  error,
  helperText,
  startIcon,
  endItem,
  rows,
  multiline,
  type = 'text',
  halfWidth,
  onBlur,
  ...props
}: InputProps) => {
  const InputComponent = multiline ? 'textarea' : 'input';
  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    onBlur && onBlur(e);
  };

  return (
    <div
      css={(theme) => ({
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        gap: 12,
        svg: { color: theme.palette.primary.main },
        'input:not(:focus):not([value = ""])': error
          ? {
              color: theme.palette.error.main,
            }
          : {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.white,
              ':hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            },
        'input:not(:focus):not([value = ""]) + div > svg': error
          ? {
              color: theme.palette.error.main,
            }
          : {
              color: theme.palette.white,
            },
        'input:-webkit-autofill:not(:focus) + div > svg': error
          ? {
              color: theme.palette.error.main,
            }
          : {
              color: theme.palette.primary.main,
            },
      })}
    >
      <InputComponent
        type={type}
        css={(theme) => ({
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: type === 'number' ? 'right' : 'left',
          borderRadius: 5,
          textTransform: 'none',
          '@media (hover: hover)': {
            ':hover': {
              backgroundColor: theme.palette.lightPurple,
              borderRadius: 5,
            },
          },
          ':focus': {
            outline: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: 'unset',
            borderRadius: 5,
          },
          '::placeholder': {
            color: theme.palette.primary.main,
          },
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.ultraLightGray,
          border: `0px solid ${theme.palette.gray}`,
          fontFamily: 'Montserrat',
          fontSize: 14,
          [theme.breakpoints.down('sm')]: {
            fontSize: 16,
          },
          fontWeight: 400,
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: startIcon ? 40 : 10,
          paddingRight: 10,
          gap: 5,
          outline: error ? `0.5px solid ${theme.palette.error}` : 'none',
          width: endItem || halfWidth ? 140 : '100%',
          [theme.breakpoints.down('md')]: {
            '&[type="time"], &[type="date"]': {
              flexGrow: 1,
              '::-webkit-date-and-time-value': {
                textAlign: 'left',
                minHeight: 22,
              },
              ':not(:focus)': {
                '::-webkit-date-and-time-value': {
                  paddingTop: 12,
                  paddingBottom: 12,
                  paddingLeft: startIcon ? 32 : 10,
                },
              },
            },
          },
          resize: 'none',
          '::-webkit-calendar-picker-indicator, ::-webkit-outer-spin-button, ::-webkit-inner-spin-button':
            {
              display: 'none',
              '-webkit-appearance': 'none',
            },
        })}
        rows={rows}
        {...props}
        onBlur={handleBlur}
      />
      {startIcon && (
        <div
          className="iconContainer"
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 40,
          }}
        >
          {startIcon}
        </div>
      )}
      {endItem}
    </div>
  );
};

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { updateReferrer } from '../reducers/graph';

export const useReferrer = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const referrer = params.get('ref');
  const clearReferrer = useCallback(() => {
    params.delete('ref');
    setParams(params);
  }, [params, setParams]);

  useEffect(() => {
    if (referrer) {
      dispatch(updateReferrer(referrer));
      clearReferrer();
    }
  }, [clearReferrer, dispatch, referrer]);
};

import { Avatar } from '@mui/material';

import { ReactComponent as DefaultFemaleAvatar } from '../assets/DefaultFemaleAvatar.svg';
import { ReactComponent as DefaultMaleAvatar } from '../assets/DefaultMaleAvatar.svg';

import { Professional } from '../types/global';
import { useFileUrl } from '../hooks/file.hooks';

interface ProfessionalAvatarProps {
  professional?: Professional;
  size?: number;
}

export const ProfessionalAvatar = ({
  professional,
  size,
}: ProfessionalAvatarProps) => {
  const url = useFileUrl(professional?.avatarId);

  return (
    <Avatar
      sx={(theme) => ({
        backgroundColor: theme.palette.ultraLightGray,
        ...(size ? { height: size, width: size } : {}),
      })}
      alt={`${professional?.title ?? ''} ${professional?.firstname ?? ''} ${
        professional?.lastname ?? ''
      }`}
      src={url}
    >
      {professional?.gender === 'FEMALE' ? (
        <DefaultFemaleAvatar />
      ) : (
        <DefaultMaleAvatar />
      )}
    </Avatar>
  );
};

/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';

import { Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  setWaitingRoom,
  updatePractice,
  updateProfessional,
} from '../reducers/graph';
import { Practice, Professional } from '../types/global';
import { useStatistics } from '../hooks/useStatistics';

import { ChatBubble } from '../components/ChatBubble';
import { ProfessionalAvatar } from '../components/ProfessionalAvatar';
import { getFullName } from '../util/professional';
import { useTheme } from '@emotion/react';
import { usePractice } from '../hooks/usePractice';
import { RootState } from '../types/graph';
import { CGUNotice } from '../components/CGUNotice';
import { QuestionLoadingIndicator } from '../components/QuestionLoadingIndicator';

export const PracticeLanding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { slug } = useParams();

  const recordStatistics = useStatistics();

  const practice = useSelector(
    (state: RootState) => state.consultation.practice
  );

  const { isLoading, error } = usePractice(slug ?? practice?.slug, {
    onSuccess: (practice: Practice) => {
      dispatch(updatePractice(practice));
      dispatch(setWaitingRoom(true));
      recordStatistics({ section: 'LANDING' }, Boolean(practice?.demo));
    },
  });

  const handleNext = useCallback(
    (professional: Professional) => {
      if (practice?.id) {
        dispatch(updateProfessional(professional));
        recordStatistics({ section: 'START' }, Boolean(practice?.demo));
        navigate(`/checkin`);
      }
    },
    [practice, dispatch, recordStatistics, navigate]
  );

  return (
    <main
      css={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      {!practice ? (
        isLoading ? (
          <QuestionLoadingIndicator stretch />
        ) : (
          <>
            <Typography variant="h5">
              Impossible de trouver le cabinet
            </Typography>
            {error?.message && <Typography>{error.message}</Typography>}
          </>
        )
      ) : (
        <>
          <div
            css={{
              flexShrink: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 24,
              paddingBottom: 24,
              gap: 30,
            }}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontWeight: 'medium',
              })}
              variant="h5"
            >
              {practice.name}
            </Typography>
            <ChatBubble assistant order={1}>
              <b>{`Avec quel médecin avez-vous rendez-vous aujourd'hui\u00A0?`}</b>
            </ChatBubble>
          </div>
          <div
            css={{
              flexGrow: 1,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-start',
              },
              alignContent: 'flex-start',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              gap: 20,
              padding: 20,
            }}
          >
            {practice.users?.map((professional, index) => (
              <div
                key={professional.id}
                onClick={() => handleNext(professional)}
                css={{
                  flexBasis: 0.5,
                  alignSelf: 'flex-start',
                  marginBottom: 20,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  gap: 12,
                  background: theme.palette.ultraLightGray,
                  ':hover': {
                    background: theme.palette.lightGray,
                  },
                  ':active': {
                    background: theme.palette.backgroundGray,
                  },
                  padding: 20,
                  borderRadius: 12,
                }}
              >
                <ProfessionalAvatar professional={professional} size={110} />
                <div
                  css={{
                    minHeight: '4.5em',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 14,
                      fontWeight: 600,
                      lineHeight: '1.5em',
                    }}
                  >
                    {getFullName(professional)}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
          <CGUNotice />
        </>
      )}
    </main>
  );
};

import { Professional } from '../types/global';

export const getFullName = (professional: Professional) =>
  `${professional.title ?? ''} ${professional.firstname ?? ''} ${
    professional.lastname ?? ''
  }`;

export const getFormalName = (professional: Professional) =>
  `${professional.title ?? ''} ${professional.lastname ?? ''}`;

export const getFromName = (professional: Professional) =>
  'du ' + getFormalName(professional);

export const getToName = (professional: Professional) =>
  'au ' + getFormalName(professional);

import { ResponseDateField, ResponseDateFieldProps } from './ResponseDateField';
import ScheduleIcon from '@mui/icons-material/Schedule';

export type ResponseTimeFieldProps = Omit<
  ResponseDateFieldProps,
  'inputType' | 'inputFormat'
>;

export const ResponseTimeField = (props: ResponseTimeFieldProps) => (
  <ResponseDateField
    inputType="time"
    inputFormat="HH:mm"
    step={5 * 60}
    startIcon={<ScheduleIcon />}
    {...props}
  />
);

/** @jsxImportSource @emotion/react */

import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import { DialogTitleWithClose } from '../components/DialogTitleWithClose';
import { useVersion } from '../hooks/useVersion';

interface AboutDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AboutDialog = ({ open, onClose }: AboutDialogProps) => {
  const version = useVersion();
  return (
    <Dialog open={open} keepMounted onClose={onClose}>
      <DialogTitleWithClose onClose={onClose}>À propros</DialogTitleWithClose>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <DialogContentText>
          Aldebaran est un assistant médical numérique à destination des
          patients et des professionnels de santé.
        </DialogContentText>
        <DialogContentText>
          Il permet de faciliter la prise en charge lors des consultations et
          participe à une mission de santé publique à destination de la
          population.
        </DialogContentText>
        <DialogContentText variant="caption">
          App : {process.env.REACT_APP_APP_VERSION} | KB : {version}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

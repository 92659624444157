import { useState, useCallback, FocusEvent, ReactNode } from 'react';

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';

import EventIcon from '@mui/icons-material/Event';

import { Input, InputOnBlurEvent, InputProps } from './Input';

export interface ResponseDateFieldProps {
  value?: number;
  label?: string;
  onChange: (value: number) => void;
  inputType?: InputProps['type'];
  step?: InputProps['step'];
  inputFormat?: string;
  errorText?: string;
  disabled?: boolean;
  startIcon?: ReactNode;
}

export const ResponseDateField = ({
  value,
  label,
  onChange,
  inputType = 'date',
  step,
  inputFormat = 'yyyy-MM-dd',
  errorText = 'Date invalide',
  disabled,
  startIcon = <EventIcon />,
}: ResponseDateFieldProps) => {
  const [error, setError] = useState(false);

  const onSubmitResponse = useCallback(
    (e: InputOnBlurEvent) => {
      if (e.target?.value) {
        const nextValue = parse(e.target.value, inputFormat, 0);
        if (isValid(nextValue)) onChange(nextValue.getTime());
        else setError(true);
      }
    },
    [inputFormat, onChange]
  );

  return (
    <Input
      defaultValue={
        value !== undefined && isValid(value)
          ? format(value, inputFormat)
          : undefined
      }
      placeholder={label}
      onBlur={onSubmitResponse}
      disabled={disabled}
      type={inputType}
      startIcon={startIcon}
      error={error}
      helperText={error ? errorText : undefined}
      step={step}
    />
  );
};

import { FocusEvent } from 'react';

import { TextField } from '@mui/material';
import isValidDate from 'date-fns/isValid';
import format from 'date-fns/format';

export interface ResponseDateTimeFieldProps {
  value?: number;
  label: string;
  onChange: (value: number) => void;
  multiline?: boolean;
  disabled?: boolean;
}

export const ResponseDateTimeField = ({
  value,
  label,
  onChange,
  disabled,
}: ResponseDateTimeFieldProps) => (
  <TextField
    sx={{ borderRadius: 3 }}
    disabled={disabled}
    defaultValue={
      value !== undefined && isValidDate(value)
        ? format(value, "yyyy-MM-dd'T'HH:mm")
        : undefined
    }
    label={label}
    onBlur={(e: FocusEvent<HTMLInputElement>) => {
      const date = new Date(e.target?.value);
      if (date !== undefined && isValidDate(date)) onChange(date.getTime());
    }}
    inputProps={{
      autofill: 'none',
      type: 'datetime-local',
    }}
    InputLabelProps={{
      shrink: true,
    }}
  />
);

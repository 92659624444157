/** @jsxImportSource @emotion/react */
import { useCallback, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';

import { ReactComponent as SkipIcon } from '../assets/Skip.svg';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useUpdateQuestionnaire } from '../hooks/questionnaire.hooks';

import { Questionnaire } from './Questionnaire';
import { NavigationBar } from '../components/NavigationBar';
import { RootState } from '../types/graph';
import { pages } from '../util/graph';
import { useStatistics } from '../hooks/useStatistics';

interface SubmissionPageProps {
  questionnaireLoading: boolean;
}

export const SubmissionPage = ({
  questionnaireLoading,
}: SubmissionPageProps) => {
  const navigate = useNavigate();
  const currentPage = useSelector(
    (state: RootState) => state.graph.currentPage
  );
  const consultation = useSelector((state: RootState) => state.consultation);
  const recordStatistics = useStatistics();

  const { updateQuestionnaire, isLoading, error, isSuccess } =
    useUpdateQuestionnaire({
      complete: true,
      onSuccess: () => {
        recordStatistics({ section: 'SUBMISSION_SUCCESS' });
        navigate('/confirmation');
      },
    });

  const onPrevious = useCallback(() => {
    navigate('/questionnaire');
  }, [navigate]);

  useEffect(() => {
    if (
      !isSuccess &&
      (!consultation?.practice ||
        !consultation.professional ||
        pages.indexOf(currentPage) !== pages.length - 1)
    )
      navigate('/');
  }, [isSuccess, consultation, currentPage, navigate]);

  if (error)
    return (
      <div
        css={{
          width: '100%',
          minHeight: 'calc(100% - 24px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: 24,
        }}
      >
        <div
          css={{
            flexGrow: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography sx={{ textAlign: 'center', mt: 2 }}>
            Une erreur est survenue
          </Typography>
          {error && (
            <Typography
              sx={{ textAlign: 'center', mt: 2, fontStyle: 'italic' }}
            >
              {error.message}
            </Typography>
          )}
        </div>
        <NavigationBar
          onNext={updateQuestionnaire}
          endIcon={<RefreshIcon />}
          onPrevious={onPrevious}
          loading={isLoading}
          label="Réessayer"
        />
      </div>
    );
  return (
    <Questionnaire
      questionnaireLoading={questionnaireLoading}
      submissionLoading={isLoading}
      nextLabel="Valider vos réponses"
      nextIcon={<SkipIcon />}
      onSubmit={updateQuestionnaire}
    />
  );
};

import { gql, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';

import { updateFormulas } from '../reducers/graph';

import { FormulaData } from '../types/kb';
import { useVersion } from './useVersion';

const GET_FORMULAS_QUERY = gql`
  query Formulas($version: Float!) {
    formulas(where: { version: $version }) {
      id
      name
      value
    }
  }
`;

export const useFormulas = () => {
  const dispatch = useDispatch();
  const version = useVersion();

  useQuery<FormulaData>(GET_FORMULAS_QUERY, {
    onError: console.log,
    variables: { version },
    onCompleted: (data) => {
      dispatch(updateFormulas(data.formulas));
    },
  });
};

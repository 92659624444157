import { useQuery } from 'react-query';
import { client } from '../util/client';

export const avatarMimeType = 'image/jpeg';

export type FileResponse = {
  id: string;
  mimeType: string;
  url: string;
};

export const useFile = (id?: string) =>
  useQuery<FileResponse>(
    ['file', id],
    async () => {
      const res = await client.get(`/file/${id}`);
      return res.data;
    },
    {
      enabled: !!id,
    }
  );

export const useFileUrl = (id?: string) => {
  const { data } = useFile(id);
  return data?.url;
};

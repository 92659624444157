import { ErrorInfo } from 'react';
import { ReactComponent as Logo } from '../assets/ShortLogo.svg';

interface ErrorPageProps {
  error?: Error;
  errorInfo?: ErrorInfo;
}

export const ErrorPage = ({ error, errorInfo }: ErrorPageProps) => {
  const href = `mailto:support@aldebaran.care?subject=Probl%C3%A8me%20rencontr%C3%A9%20sur%20l'application%20pro&body=Bonjour%2C%0D%0A%0D%0AJ'ai%20rencontr%C3%A9%20l'erreur%20suivante%20en%20utilisant%20l'application%20%3A%0D%0A%0D%0A${error?.toString()}%0D%0A%0D%0AMerci%2C%0D%0A%0D%0A`;
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          width: '30%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Logo />
        <p style={{ fontSize: 18, fontWeight: 500 }}>
          Nous rencontrons des difficultés à charger l'application.
        </p>
        <p>
          Veuillez contacter le support <a href={href}>en cliquant ici.</a>
        </p>
        {error && (
          <p
            style={{
              marginTop: 20,
              padding: 12,
              backgroundColor: '#f0f0f0',
              borderRadius: 8,
              fontFamily: 'monospace',
            }}
          >
            <code>{error.toString()}</code>
          </p>
        )}
        {errorInfo && (
          <p>
            <code
              style={{
                padding: 12,
                backgroundColor: '#f0f0f0',
                borderRadius: 8,
                fontFamily: 'monospace',
              }}
            >
              {errorInfo.componentStack.toString()}
            </code>
          </p>
        )}
      </div>
    </div>
  );
};

/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import isValid from 'date-fns/isValid';

import { client } from '../util/client';

import {
  GraphState,
  QuestionResponseStore,
  QuestionnaireState,
  QuestionnaireSummary,
  RootState,
} from '../types/graph';

import { useCheckCondition } from './useCheckCondition';
import { useVersion } from './useVersion';
import { useConsultationMotives } from './useConsultationMotives';
import { useEvaluate } from './useEvaluate';
import { useStatistics } from './useStatistics';
import { allSections, assertionKeys, valueKeys } from '../util/graph';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import {
  ConsultationMotive,
  Patient,
  Practice,
  Professional,
} from '../types/global';
import { updateQuestionnaireId } from '../reducers/graph';

const excludedQuestions = ['ID#NPN', 'ID#D1', 'ID#D2', 'ID#A1'];

type PatientInput = Omit<Patient, 'id'>;
type ReportInput = {
  data: string[];
  questionnaire: QuestionnaireSummary;
  graph: GraphState;
};
type ConsultationInput = {
  checkedIn: boolean;
  complete: boolean;
  date: Date;
  newPatient: boolean;
  professionalId: Professional['id'];
  practiceId: Practice['id'];
  motives: ConsultationMotive['id'][];
  version: string;
};

interface UpdateQuestionnaireInput {
  id: string;
  version: string;
  patient: PatientInput;
  consultation: ConsultationInput;
  report: ReportInput;
  data: QuestionnaireSummary;
  state: QuestionnaireState;
  graph: GraphState;
  responses: QuestionResponseStore;
}

const useQuestionnaireSummary = () => {
  const validQuestions = useSelector((state: RootState) =>
    state.questionnaire.blocks.flat()
  );
  const responses = useSelector((state: RootState) => state.userResponses);
  const questions = useSelector((state: RootState) => state.kb.questions);
  const pageAssignments = useSelector(
    (state: RootState) => state.questionnaire.pageAssignments
  );

  const questionnaireSummary = useMemo(
    () =>
      without(
        validQuestions,
        ...excludedQuestions
      ).reduce<QuestionnaireSummary>((acc, extId) => {
        const q = questions[extId];
        if (!q || !q.label) return acc;

        if (!allSections.includes(q.section) && !pageAssignments[q.extId])
          return acc;

        acc[extId] = {
          question: q.label,
          responses: q.responses
            .map((r) => {
              const value = responses?.[q.extId]?.[r.extId];
              return value === true
                ? r.label
                : r.unit?.plural && value
                ? `${value} ${
                    value === 1
                      ? r.unit.singular ?? r.unit.plural
                      : r.unit.plural
                  }`
                : value;
            })
            .filter((r) => r !== false && r !== undefined),
        };
        if (acc[extId].responses.length === 0)
          acc[extId].responses = ['Non renseigné'];
        return acc;
      }, {}),
    [pageAssignments, questions, responses, validQuestions]
  );
  return questionnaireSummary;
};

export const useReport = () => {
  const userResponses = useSelector((state: RootState) => state.userResponses);
  const responses = useSelector((state: RootState) => state.kb.responses);
  const evaluate = useEvaluate();

  const checkCondition = useCheckCondition();

  return uniq(
    Object.values(userResponses)
      .flatMap((r) =>
        Object.entries(r).flatMap(([extId, userValue]) => {
          if (
            userValue !== false &&
            userValue !== '' &&
            userValue !== undefined
          ) {
            return responses[extId]?.reports
              .filter((report) => checkCondition(report.condition))
              .map((report) => {
                const [reportValue, clause] = report.value.split('-<EVALUE>->');
                const unit = responses[extId].unit;
                const graphValue = clause
                  ? evaluate(clause)?.toString()
                  : unit?.plural
                  ? `${userValue} ${
                      userValue === 1
                        ? unit.singular ?? unit.plural
                        : unit.plural
                    }`
                  : userValue?.toString().trim();
                return reportValue.replace('#?', graphValue ?? '');
              });
          }
          return [];
        })
      )
      .filter((r) => r)
  );
};

const excludedValues = [
  valueKeys.firstName,
  valueKeys.lastName,
  valueKeys.dateOfBirth,
];

interface UseUpdateQuestionnaireOptions {
  onSuccess?: () => void;
  onError?: (error?: AxiosError) => void;
  checkedIn?: boolean;
  complete?: boolean;
}

export const useUpdateQuestionnaire = ({
  onSuccess,
  onError,
  checkedIn = false,
  complete = false,
}: UseUpdateQuestionnaireOptions) => {
  const dispatch = useDispatch();
  const version = useVersion();

  const check = useCheckCondition();
  const questionnaireId = useSelector(
    (state: RootState) => state.questionnaireId
  );
  const graph = useSelector((state: RootState) => state.graph);
  const consultation = useSelector((state: RootState) => state.consultation);
  const userResponses = useSelector((state: RootState) => state.userResponses);
  const questionnaire = useSelector((state: RootState) => state.questionnaire);

  const recordStatistics = useStatistics();

  const newPatient =
    check('[Prestation: #00‹]-(a_occurrence)->[Occurrence: N=1]') ||
    check('[Prestation: #00‹]-(a_occurrence)->[Occurrence: N<=1]');

  const questionnaireSummary = useQuestionnaireSummary();
  const report = useReport();
  const consultationMotives = useConsultationMotives(report);

  const handleSuccess = useCallback(
    ({ id }: { id: string }) => {
      onSuccess?.();
      dispatch(updateQuestionnaireId(id));
    },
    [dispatch, onSuccess]
  );

  const handleError = useCallback(
    (err: AxiosError) => {
      onError?.(err);
      recordStatistics({ section: 'SUBMISSION_ERROR' });
    },
    [onError, recordStatistics]
  );

  const { mutate, ...rest } = useMutation({
    mutationFn: (input: UpdateQuestionnaireInput) =>
      client.post('/questionnaire', input).then((res) => res.data),
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const updateQuestionnaire = useCallback(() => {
    const date = new Date(Number(graph.values[valueKeys.date]?.value));
    const professionalId = consultation.professional?.id;
    const practiceId = consultation.practice?.id;

    if (!version || !professionalId || !practiceId) return;

    const dateOfBirth = graph.values[valueKeys.dateOfBirth]?.value as
      | number
      | undefined;
    const patient = {
      firstName: graph.values[valueKeys.firstName]?.value?.toString(),
      lastName: graph.values[valueKeys.lastName]?.value?.toString(),
      dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : undefined,
      sex: graph.assertions.includes(assertionKeys.sexMale)
        ? 'MALE'
        : graph.assertions.includes(assertionKeys.sexFemale)
        ? 'FEMALE'
        : undefined,
    };

    mutate({
      id: questionnaireId,
      version: version.toString(),
      patient,
      consultation: {
        date: isValid(date) ? date : new Date(),
        version: version.toString(),
        newPatient,
        practiceId,
        professionalId,
        motives: consultationMotives,
        checkedIn,
        complete,
      },
      report: {
        data: report,
        questionnaire: questionnaireSummary,
        graph: {
          ...graph,
          assertions: graph.assertions,
          values: omit(graph.values, excludedValues),
        },
      },
      data: questionnaireSummary,
      graph,
      state: questionnaire,
      responses: omit(userResponses, excludedQuestions),
    });
  }, [
    graph,
    consultation,
    version,
    mutate,
    questionnaireId,
    newPatient,
    consultationMotives,
    report,
    questionnaireSummary,
    questionnaire,
    userResponses,
    complete,
    checkedIn,
  ]);

  return { updateQuestionnaire, ...rest };
};

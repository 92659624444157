/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';

import { Skeleton, Typography } from '@mui/material';

import { updateProfessional } from '../reducers/graph';
import { useQuery } from 'react-query';
import { Patient, Professional } from '../types/global';
import { RootState } from '../types/graph';
import { ResponseButton } from '../components/ResponseButton';
import { FormCard } from '../components/FormCard';
import { useStatistics } from '../hooks/useStatistics';
import { client } from '../util/client';

interface StepProps {
  onNext: () => void;
  onPrevious?: () => void;
  practiceId?: string;
  professional?: Professional;
  patient?: Patient;
}

const LoadingPlaceholder = () => (
  <>
    <Skeleton variant="rectangular" sx={{ mb: 2 }} />
    <Skeleton variant="text" />
    <Skeleton variant="text" />
  </>
);

// const Tutorial = ({ onNext, onPrevious }: StepProps) => (
//   <FormCard onNext={onNext} onPrevious={onPrevious}>
//     <div
//       css={{
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         flexGrow: 1,
//       }}
//     >
//       <Typography variant="h5">Comment fonctionne cet assistant ?</Typography>
//       <Typography css={{ marginTop: 24 }}>
//         Nous allons vous poser quelques questions sur votre santé et votre
//         bien-être général pour préparer votre consultation.
//       </Typography>
//       <Typography css={{ marginTop: 24 }}>
//         Vos réponses seront synthétisées en un compte-rendu transmis au
//         professionnel de santé avant votre prise en charge.
//       </Typography>
//     </div>
//   </FormCard>
// );

// const DataPolicy = ({ onNext, onPrevious }: StepProps) => (
//   <FormCard onNext={onNext} onPrevious={onPrevious}>
//     <div
//       css={{
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         flexGrow: 1,
//       }}
//     >
//       <Typography variant="h5">La confidentialité avant tout</Typography>
//       <Typography css={{ marginTop: 24 }}>
//         Aucune information ne peut être transmise sans votre consentement.
//       </Typography>
//       <Typography css={{ marginTop: 24 }}>
//         Vos données sont protégées selon les réglementations françaises et
//         européennes en vigueur et les informations médicales recueillies sont
//         uniquement accessibles par votre médecin.
//       </Typography>
//     </div>
//   </FormCard>
// );

const SelectProfessional = ({
  onNext,
  onPrevious,
  practiceId,
  professional,
}: StepProps) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(professional);

  const {
    data: professionals,
    isLoading,
    error,
  } = useQuery<Professional[], AxiosError>(
    ['practice', practiceId, 'professionals'],
    async () =>
      client(`/practice/${practiceId}/professionals`).then(
        (response) => response?.data
      )
  );

  const handleNext = useCallback(() => {
    if (selected) {
      dispatch(updateProfessional(selected));
      onNext();
    }
  }, [dispatch, onNext, selected]);

  // useEffect(() => {
  //   if (professionals?.length === 1) {
  //     dispatch(updateProfessional(professionals[0]));
  //     onNext();
  //   }
  // }, [dispatch, handleNext, onNext, professionals]);

  return (
    <FormCard onNext={handleNext} onPrevious={onPrevious} disabled={!selected}>
      {isLoading ? (
        <LoadingPlaceholder />
      ) : !professionals ? (
        <>
          <Typography variant="h5">Impossible de trouver le cabinet</Typography>
          {error?.message && <Typography>{error.message}</Typography>}
        </>
      ) : (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            gap: 24,
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 16,
            }}
          >
            <Typography css={{ marginTop: 24 }}>
              Pour commencer, quel médecin venez-vous consulter aujourd'hui ?
            </Typography>
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 16,
            }}
          >
            {professionals.map((pro) => (
              <ResponseButton
                key={pro.id}
                selected={selected?.id === pro.id}
                label={`${pro.title ? pro.title : ''} ${pro.firstname} ${
                  pro.lastname
                }`}
                onChange={(value) => {
                  if (value) setSelected(pro);
                }}
              />
            ))}
          </div>
        </div>
      )}
    </FormCard>
  );
};

const steps = ['professional'];

export const Intro = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const step = searchParams.get('step') ?? steps[0];

  const practice = useSelector(
    (state: RootState) => state.consultation.practice
  );
  const professional = useSelector(
    (state: RootState) => state.consultation.professional
  );
  const patient = useSelector((state: RootState) => state.consultation.patient);

  const recordStatistics = useStatistics();

  const changeStep = useCallback(
    (step: string) => {
      setSearchParams({ step });
    },
    [setSearchParams]
  );

  const onNext = useCallback(() => {
    const currentStep = steps.indexOf(step);
    if (currentStep === -1) return;
    else if (steps[currentStep + 1]) changeStep(steps[currentStep + 1]);
    else {
      recordStatistics({ section: 'INTRO' });
      navigate('/questionnaire');
    }
  }, [step, changeStep, recordStatistics, navigate]);

  const onPrevious = useCallback(() => {
    const currentStep = steps.indexOf(step);
    if (currentStep === -1) return;
    else if (currentStep === 0 && practice?.slug) navigate(`/${practice.slug}`);
    else if (steps[currentStep - 1]) changeStep(steps[currentStep - 1]);
    else navigate('/');
  }, [step, practice, navigate, changeStep]);

  useEffect(() => {
    if (!practice?.id && steps.indexOf(step) > steps.indexOf('practice'))
      changeStep('practice');
    else if (
      !professional &&
      steps.indexOf(step) > steps.indexOf('professional')
    )
      changeStep('professional');
    else if (!steps.includes(step)) changeStep(steps[0]);
  }, [changeStep, practice, professional, step]);

  return (
    <main
      css={{
        width: '100%',
        height: '100%',
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      {/* {step === 'tutorial' && (
        <Tutorial onNext={onNext} onPrevious={onPrevious} />
      )}
      {step === 'data' && (
        <DataPolicy onNext={onNext} onPrevious={onPrevious} />
      )} */}
      {step === 'professional' && (
        <SelectProfessional
          onNext={onNext}
          onPrevious={onPrevious}
          practiceId={practice?.id}
          professional={professional}
          patient={patient}
        />
      )}
    </main>
  );
};

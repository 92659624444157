import { HTMLAttributes } from 'react';
import styled from '@emotion/styled';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  filled?: boolean;
}

export const Button = styled.button<ButtonProps>(({ filled, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'left',
  borderRadius: 5,
  textTransform: 'none',
  '&, :-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active':
    {
      backgroundColor: filled
        ? theme.palette.primary.main
        : theme.palette.ultraLightGray,
    },
  border: `0px solid ${theme.palette.gray}`,
  fontFamily: 'Montserrat',
  color: filled ? theme.palette.white : theme.palette.primary.main,
  fontSize: 14,
  [theme.breakpoints.down('sm')]: {
    fontSize: 15,
  },
  fontWeight: 400,
  paddingLeft: 10,
  paddingRight: 10,
  gap: 5,
  ':focus': {
    outline: `1px solid ${theme.palette.primary.main}`,
  },
  '@media (hover: hover)': {
    ':hover': {
      backgroundColor: filled
        ? theme.palette.primary.dark
        : theme.palette.lightPurple,
    },
  },
  cursor: 'pointer',
  '-webkit-tap-highlight-color': 'transparent',
  'user-select': 'none',
  transition: 'all ease 0.2s',
}));

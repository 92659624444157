/** @jsxImportSource @emotion/react */
import { ChangeEvent, FormEvent, useCallback, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { ViewableQuestion } from '../types/global';
import { Button } from './Button';
import { Button as MaterialButton } from '@mui/material';
import { ResponseButton } from './ResponseButton';

interface OtherDialogProps extends ResponseOtherFieldProps {
  open: boolean;
  questionLabel: ViewableQuestion['label'];
  onClose: () => void;
}

const OtherDialog = ({
  value,
  label,
  questionLabel,
  onChange,
  open,
  onClose,
}: OtherDialogProps) => {
  const [localValue, setLocalValue] = useState(value ?? '');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onChange(localValue);
      onClose();
    },
    [localValue, onChange, onClose]
  );

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth>
      <form
        onSubmit={onSubmit}
        css={{
          height: '50%',
          paddingTop: fullScreen ? '20%' : undefined,
          paddingBottom: 16,
        }}
      >
        {questionLabel && (
          <DialogTitle
            sx={{
              display: 'block',
              fontSize: 18,
              maxHeight: '50%',
              overflowY: 'auto',
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
            }}
          >
            {questionLabel}
          </DialogTitle>
        )}
        <DialogContent>
          <TextField
            sx={{ borderRadius: 3, width: '100%', mt: 1 }}
            label={label}
            value={localValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setLocalValue(e.target?.value)
            }
            inputProps={{ autofill: 'none', type: 'text' }}
            InputProps={{
              endAdornment: localValue && (
                <ClearIcon onClick={() => setLocalValue('')} />
              ),
            }}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <MaterialButton onClick={onClose}>Annuler</MaterialButton>
          <MaterialButton variant="contained" onClick={onSubmit}>
            Enregistrer
          </MaterialButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

interface ResponseOtherFieldProps {
  value?: string;
  label: string;
  questionLabel: ViewableQuestion['label'];
  multiselect?: boolean;
  onChange: (value: string) => void;
}

export const ResponseOtherField = ({
  value,
  label,
  questionLabel,
  multiselect,
  onChange,
}: ResponseOtherFieldProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const selected = Boolean(value?.length);
  return (
    <>
      <ResponseButton
        label={value ?? label}
        selected={selected}
        onChange={() => setDialogOpen(true)}
        multiselect={multiselect}
        freeform
      />
      <OtherDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        value={value}
        label={label}
        questionLabel={questionLabel}
        onChange={onChange}
      />
    </>
  );
};

import { Value, ViewableQuestion } from '../types/global';
import { FormulaStore, GraphState, ValueStore } from '../types/graph';
import format from 'date-fns/format';
import differenceInYears from 'date-fns/differenceInYears';
import differenceInMonths from 'date-fns/differenceInMonths';
import startOfToday from 'date-fns/startOfToday';
import startOfDay from 'date-fns/startOfDay';
import config from '../config';

export type Page =
  | 'Le patient'
  | 'La consultation'
  | 'Besoins'
  | 'Informations médicales'
  | 'Prévention'
  | 'Conclusion';

export const sections: Record<Page, string[]> = {
  'Le patient': ['INS'],
  'La consultation': ['CONTEXTE'],
  Besoins: ['MOTIF'],
  'Informations médicales': ['ANTECEDENTS', 'TRAITEMENTS'],
  Prévention: ['HYGIENE_DE_VIE'],
  Conclusion: ['RECAP'],
};

export const sectionIntroductions: Partial<Record<Page, string>> = {
  'Le patient':
    "L'identification du patient est une étape importante pour une prise en charge médicale adaptée.",
  'La consultation':
    "La date, l'heure de la consultation, ainsi que votre dernier rendez-vous avec le médecin permet d'adapter la prise en charge.",
  Besoins:
    "Vous pouvez avoir plusieurs raisons de voir votre médecin, même s'il n'aura peut-être pas le temps de tout traiter cette fois-ci, cochez-en autant que nécessaire.",
  'Informations médicales':
    "Renseigner ou mettre à jour vos informations médicales permet à votre médecin de mieux vous connaître et d'établir un meilleur diagnostic.",
  Prévention:
    "Faire attention à certains facteurs de risques et maintenir une bonne hygiène de vie permet de prévenir l'apparition de futurs problèmes de santé.",
  Conclusion:
    'Nous avons bientôt fini de préparer la consultation. Vous pouvez encore ajouter des éléments à ne pas oublier, votre médecin en prendra connaissance avant de vous recevoir.',
};

export const assertionKeys = {
  sexMale: '[Personne: #x‹]-(02.a_genre)->[Genre: Masculin]',
  sexFemale: '[Personne: #x‹]-(02.a_genre)->[Genre: Féminin]',
};

export const valueKeys = {
  firstName:
    '[Personne: #x‹]-(01.DESCR)->[Prénom: #‹]-(0.a_pour_valeur)->[Valeur: *]',
  lastName:
    '[Personne: #x‹]-(01.DESCR)->[Nom: #‹]-(0.a_pour_valeur)->[Valeur: *]',
  date: '[Moment: #t0‹]-(a_date)->[Date]-(0.a_pour_valeur)->[Valeur: *]',
  dateOfBirth:
    '[Personne: #x‹]-(00.date_naissance)->[Naissance: #‹]-(0.a_pour_valeur)->[Valeur: *]',
};

export const pages = Object.keys(sections) as Page[];
export const allSections = Object.values(sections).flat();

export const valueRegex = /\[Valeur: (.+?)\](?:-\(unité\)->\[Unité: (.+?)\])?/;
export const standardValue = '[Valeur: *]';
const valueGraphRegex = /\{(\*)?(.+?)(?:-\(unité\)->\[Unité: (.+?)\])?(\*)?\}/g;

const helpers = {
  round: Math.round,
  pow: Math.pow,
  ZeroSiNA: (value: any) => (!isNaN(value) ? Number(value) : 0),
  isNumber: (value: any) => !isNaN(value),
  not: (value: any) => !value,
  answered: (value: Value) => (value !== undefined ? 1 : 0),
  differenceInYears,
  differenceInMonths,
  startOfToday,
  startOfDay,
  setTimeOnDate: (date: Date, time: Date) => {
    const d = new Date(date);
    const t = new Date(time);
    return d.setHours(
      t.getHours(),
      t.getMinutes(),
      t.getSeconds(),
      t.getMilliseconds()
    );
  },
};

export const evaluate = (
  graphValues: ValueStore,
  formulas: FormulaStore,
  clause: string
): Value => {
  const reformatted = clause.match(/\[(.+)\]/);
  const withoutValues = reformatted?.[1] ?? clause;

  if (!withoutValues) return undefined;

  const values: Value[] = [];

  if (withoutValues === '{the system date}')
    return format(new Date(), 'd MMMM y');

  if (withoutValues === '{the system time}')
    return format(new Date(), "HH'h'mm");

  try {
    let i = 0;
    const before = withoutValues.replaceAll(
      /\{\*.*?\[Formule: (.+?)\].*?\*\}/g,
      (_, formula) => formulas[formula] ?? 'undefined'
    );
    const toEvaluate = before.replaceAll(
      valueGraphRegex,
      (_, partial, a, unit) => {
        const assertion = a.trim();
        const key = partial
          ? Object.keys(graphValues).find((v) => v.includes(assertion))
          : assertion;

        if (!unit || (key && graphValues[key].unit?.plural === unit)) {
          values[i] = graphValues[key]?.value;
        } else {
          values[i] = undefined;
        }
        return `values[${i++}]`;
      }
    );

    // eslint-disable-next-line no-new-func
    const calculator = new Function(
      ...Object.keys(helpers),
      'values',
      `return (${toEvaluate})`
    );
    const result = calculator(...Object.values(helpers), values);
    if (result !== true && result !== false && isNaN(result)) return undefined;
    return result;
  } catch (e) {
    if (config.debug.evaluate) console.error(e, clause);
    return undefined;
  }
};

export const checkClause =
  (graph: GraphState, formulas: FormulaStore) => (conditionString: string) =>
    conditionString.split('<-<ET>->').every((c) => {
      if (!c) return true;

      const negationMatch = c.match(/(^NOT )?(.+)/) ?? [];
      const [, not, condition] = negationMatch;

      const sectionMatch = condition.match(/\[>(.+)<]/);
      if (sectionMatch) {
        const [, section] = sectionMatch;

        const res = graph.options.includes(section.toUpperCase());
        return not ? !res : res;
      }

      const [standard, evaluated] = condition.split('<EVALUE>->');
      if (evaluated)
        return Boolean(evaluate(graph.values, formulas, evaluated));

      return not
        ? !graph.assertions.includes(standard)
        : graph.assertions.includes(standard);
    });

export const checkCondition = (
  graph: GraphState,
  formulas: FormulaStore,
  conditionString: string
): boolean => {
  if (!conditionString) return true;
  const conditions = conditionString.split('!!');

  const check = checkClause(graph, formulas);

  return conditions.every((c, i) => (i % 2 === 0 ? check(c) : !check(c)));
};

export const sortQuestions = (
  input: ViewableQuestion[],
  insertionOrder: ViewableQuestion['extId'][]
): ViewableQuestion[][] => {
  const heads = input.filter(
    (q) => !q.after || !input.find((r) => r.extId === q.after)
  );

  if (!heads.length) return [];

  const questionsByAfter = input.reduce<
    Record<ViewableQuestion['extId'], ViewableQuestion[]>
  >((acc, q) => {
    if (q.after) {
      if (acc[q.after]) acc[q.after].push(q);
      else acc[q.after] = [q];
    }
    return acc;
  }, {});

  const unwindTree = (q: ViewableQuestion): ViewableQuestion[] => {
    if (!questionsByAfter[q.extId]) return [q];
    return [
      q,
      ...questionsByAfter[q.extId]
        .sort(
          (a, b) =>
            insertionOrder.indexOf(b.extId) - insertionOrder.indexOf(a.extId)
        )
        .flatMap(unwindTree),
    ];
  };

  return heads
    .map((head) => unwindTree(head))
    .sort((a, b) => b.length - a.length);
};

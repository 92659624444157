import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../types/graph';
import { useStatistics } from './useStatistics';

export const useQuestionnaireStatistics = () => {
  const activeQueue = useSelector(
    (state: RootState) => state.questionnaire.activeQueue
  );

  const currentPage = useSelector(
    (state: RootState) => state.graph.currentPage
  );

  const responses = useSelector((state: RootState) => state.userResponses);

  const postStatistics = useStatistics();
  const recordStatistics = useCallback(() => {
    postStatistics({
      section: currentPage,
      numberOfQuestions: activeQueue.length,
      numberOfResponses: activeQueue.filter(
        (extId) => responses[extId] && Object.keys(responses[extId]).length > 0
      ).length,
    });
  }, [activeQueue, currentPage, postStatistics, responses]);

  return recordStatistics;
};

import { useState, useCallback, FocusEvent } from 'react';
import { format, isPast, isValid, parse } from 'date-fns';

import InputMask from 'react-input-mask';
import EventIcon from '@mui/icons-material/Event';

import { Input } from './Input';

export interface ResponseDateFieldProps {
  value?: number;
  label?: string;
  onChange: (value: number) => void;
  multiline?: boolean;
  includeTime?: boolean;
  disabled?: boolean;
}

export const ResponseDOBField = ({
  value,
  label = 'Date de naissance',
  onChange,
  disabled,
}: ResponseDateFieldProps) => {
  const [error, setError] = useState(false);

  const onSubmitResponse = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (e.target?.value) {
        const nextValue = parse(e.target.value, 'dd/MM/yyyy', new Date());
        if (isValid(nextValue) && isPast(nextValue))
          onChange(nextValue.getTime());
        else setError(true);
      }
    },
    [onChange]
  );

  return (
    <InputMask
      mask="99/99/9999"
      defaultValue={
        value && isValid(value) ? format(value, 'dd/MM/yyyy') : undefined
      }
      alwaysShowMask={false}
      onChange={() => setError(false)}
      onBlur={onSubmitResponse}
    >
      {/* @ts-ignore error in InputMask types */}
      {(inputProps) => (
        <Input
          label={label}
          placeholder="jj/mm/aaaa"
          startIcon={<EventIcon />}
          error={error}
          helperText={
            error ? 'Veuillez entrer une date de naissance valide' : undefined
          }
          {...inputProps}
        />
      )}
    </InputMask>
  );
};

/** @jsxImportSource @emotion/react */

import { Typography } from '@mui/material';

export const Copyright = () => (
  <div css={{ position: 'relative', height: 0 }}>
    <div
      css={{
        position: 'absolute',
        bottom: 10,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Typography variant="caption" color="primary">
        © Aldebaran 2023
      </Typography>
    </div>
  </div>
);

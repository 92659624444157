/** @jsxImportSource @emotion/react */
import { ReactNode, useCallback, useState } from 'react';
import { Menu } from './Menu';
import { QuestionnaireHeader } from './QuestionnaireHeader';
import { DefaultHeader } from './DefaultHeader';

export interface HeaderContentProps {
  menu: ReactNode;
  onOpenMenu: () => void;
}

interface HeaderProps {
  questionnaire?: boolean;
}

export const Header = ({ questionnaire }: HeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const onOpenMenu = useCallback(() => setMenuOpen(true), [setMenuOpen]);
  const onCloseMenu = useCallback(() => setMenuOpen(false), [setMenuOpen]);

  const menu = <Menu open={menuOpen} onClose={onCloseMenu} header />;

  return questionnaire ? (
    <QuestionnaireHeader menu={menu} onOpenMenu={onOpenMenu} />
  ) : (
    <DefaultHeader menu={menu} onOpenMenu={onOpenMenu} />
  );
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { Professional } from '../types/global';
import { client } from '../util/client';

export const useProfessional = (
  slug: string | undefined,
  options: UseQueryOptions<Professional, AxiosError> = {}
) => {
  return useQuery<Professional, AxiosError>(
    ['professional', slug],
    async () =>
      client(`/user/public/${slug}`).then((response) => response?.data),
    {
      enabled: !!slug,
      ...options,
    }
  );
};

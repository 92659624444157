/** @jsxImportSource @emotion/react */
import { useCallback, useState, useEffect, ReactNode } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../types/graph';
import { previousPage, nextPage, changePage } from '../reducers/graph';
import { pages, sectionIntroductions } from '../util/graph';

import { NavigationBar } from '../components/NavigationBar';
import { Question } from '../components/Question';
import { QuestionLoadingIndicator } from '../components/QuestionLoadingIndicator';

import { Alert, Snackbar, SnackbarProps } from '@mui/material';

import { useInvalidQuestions } from '../hooks/useInvalidQuestions';
import { useQuestionnaireStatistics } from '../hooks/useQuestionnaireStatistics';
import { DemoDisclaimer } from '../components/DemoDisclaimer';
import config from '../config';
import { SectionIntroduction } from '../components/SectionIntroduction';
import { useScrollProgress } from '../hooks/useScrollProgress';
import { QuestionnaireLayout } from '../components/QuestionnaireLayout';
import { useUpdateQuestionnaire } from '../hooks/questionnaire.hooks';

interface ErrorSnackbarProps {
  open: SnackbarProps['open'];
  onClose: SnackbarProps['onClose'];
}

const ErrorSnackbar = ({ open, onClose }: ErrorSnackbarProps) => (
  <Snackbar
    open={open}
    onClose={onClose}
    autoHideDuration={3000}
    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
  >
    <Alert severity="error" css={{ margin: 32 }}>
      Veuillez répondre à toutes les questions obligatoires
    </Alert>
  </Snackbar>
);

interface QuestionnaireProps {
  page?: string;
  questionnaireLoading?: boolean;
  submissionLoading?: boolean;
  nextLabel?: string;
  nextIcon?: ReactNode;
  onSubmit?: () => void;
  onPrevious?: () => void;
  blockPrevious?: boolean;
  topElement?: ReactNode;
}

export const Questionnaire = ({
  page,
  questionnaireLoading,
  submissionLoading,
  nextLabel,
  nextIcon,
  topElement,
  onSubmit,
  onPrevious,
  blockPrevious,
}: QuestionnaireProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, scrollToTop] = useScrollProgress();

  const [showErrors, setShowErrors] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const consultation = useSelector((state: RootState) => state.consultation);
  const activeQueue = useSelector(
    (state: RootState) => state.questionnaire.activeQueue
  );

  const currentPage = useSelector(
    (state: RootState) => state.graph.currentPage
  );
  const currentQuestion = useSelector(
    (state: RootState) => state.questionnaire.currentQuestion
  );

  const invalidQuestions = useInvalidQuestions();

  const { updateQuestionnaire, isLoading } = useUpdateQuestionnaire({
    onSuccess: () => {
      onChangePage();
      dispatch(nextPage());
    },
  });

  // const { mutate: updatePatient } = useUpdatePatient();

  const recordStatistics = useQuestionnaireStatistics();

  const onChangePage = useCallback(() => {
    recordStatistics();
    scrollToTop();
    setShowErrors(false);
    setShowErrorMessage(false);
  }, [recordStatistics, scrollToTop]);

  const onPreviousPage = useCallback(() => {
    onChangePage();
    if (onPrevious) {
      onPrevious();
    } else {
      if (pages.indexOf(currentPage) === 0) navigate(`/professional`);
      dispatch(previousPage());
    }
  }, [currentPage, dispatch, navigate, onChangePage, onPrevious]);

  const onNextPage = useCallback(() => {
    if (invalidQuestions.length) {
      // second attempt
      if (showErrors === true) {
        setShowErrorMessage(true);
      } else {
        setShowErrors(true);
        const element = document.getElementById(invalidQuestions[0]);
        window.scrollTo({
          top: (element?.offsetTop ?? 100) - 100,
          behavior: 'smooth',
        });
      }
    } else {
      if (onSubmit) onSubmit();
      else updateQuestionnaire();
    }
  }, [invalidQuestions, onSubmit, showErrors, updateQuestionnaire]);

  useEffect(() => {
    if (!consultation.practice?.id) navigate('/');
    else if (pages.indexOf(currentPage) === pages.length - 1)
      navigate('/submit');
  }, [consultation, navigate, currentPage, page, dispatch]);

  useEffect(() => {
    if (page && currentPage !== page) dispatch(changePage(page));
  }, []);

  return (
    <QuestionnaireLayout
      topElement={
        topElement ?? (
          <SectionIntroduction text={sectionIntroductions[currentPage]} />
        )
      }
      bottomElement={
        <NavigationBar
          onNext={onNextPage}
          endIcon={nextIcon}
          onPrevious={blockPrevious ? undefined : onPreviousPage}
          disabled={questionnaireLoading}
          loading={submissionLoading || isLoading}
          label={nextLabel ?? pages?.[pages.indexOf(currentPage) + 1]}
        />
      }
    >
      {activeQueue.map((extId) => (
        <Question
          key={extId}
          extId={extId}
          currentPage={currentPage}
          inactive={currentQuestion !== extId}
          loading={currentQuestion === extId && questionnaireLoading}
          error={showErrors && invalidQuestions.includes(extId)}
          resetErrors={() => setShowErrors(false)}
        />
      ))}
      {!activeQueue?.length && questionnaireLoading && (
        <QuestionLoadingIndicator stretch />
      )}
      <ErrorSnackbar
        open={showErrorMessage}
        onClose={() => setShowErrorMessage(false)}
      />
    </QuestionnaireLayout>
  );
};

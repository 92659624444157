import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../types/graph';
import { checkCondition } from '../util/graph';

export const useCheckCondition = () => {
  const graph = useSelector((state: RootState) => state.graph);
  const formulas = useSelector((state: RootState) => state.kb.formulas);

  const check = useCallback(
    (condition: string) => checkCondition(graph, formulas, condition),
    [graph, formulas]
  );
  return check;
};

/** @jsxImportSource @emotion/react */
import { ReactNode, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import { HelpDialog } from '../pages/HelpDialog';
import { AboutDialog } from '../pages/AboutDialog';
import { usePurgeState } from '../hooks/usePurgeState';

import config from '../config';

interface MenuItem {
  label: string;
  icon: ReactNode;
  onClick?: () => void;
}

interface MenuProps {
  open?: boolean;
  onClose?: () => void;
  header?: boolean;
  compact?: boolean;
}

export const Menu = ({ open, onClose, header, compact }: MenuProps) => {
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [aboutDialogOpen, setAboutDialogOpen] = useState(false);
  const navigate = useNavigate();

  const purgeState = usePurgeState();

  const reset = useCallback(() => {
    purgeState();
    navigate('/practice');
  }, [purgeState, navigate]);

  const closeDialogs = () => {
    setHelpDialogOpen(false);
    setAboutDialogOpen(false);
  };

  const menuItems: MenuItem[] = [
    {
      label: 'Réinitialiser',
      icon: <RotateLeftIcon />,
      onClick: reset,
    },
    {
      label: 'Assistance',
      icon: <HelpOutlineOutlinedIcon />,
      onClick: () => setHelpDialogOpen(true),
    },
    {
      label: 'Comment ça marche',
      icon: <InfoOutlinedIcon />,
      onClick: () => setAboutDialogOpen(true),
    },
  ];

  if (config.debug.panel)
    menuItems.push({
      label: 'Debug',
      icon: <ContentPasteIcon />,
      onClick: () => navigate('/debug'),
    });

  const innerMenu = (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        svg: { color: theme.palette.white },
        height: '100%',
        width: '100%',
        paddingTop: 8,
      })}
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}
    >
      <List>
        {menuItems.map((item) => (
          <ListItem
            css={compact && { paddingTop: 4, paddingBottom: 4 }}
            key={item.label}
            disablePadding
          >
            <ListItemButton onClick={item.onClick}>
              <ListItemIcon
                css={{ minWidth: 48, marginLeft: compact ? 0 : 12 }}
              >
                {item.icon}
              </ListItemIcon>
              {!compact && (
                <ListItemText
                  primary={item.label}
                  css={{ span: { fontWeight: 500 }, marginRight: 12 }}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {header ? (
        <Drawer
          sx={{
            minWidth: 280,
            maxWidth: '80%',
          }}
          anchor="right"
          open={open}
          onClose={onClose}
        >
          {innerMenu}
        </Drawer>
      ) : (
        innerMenu
      )}
      <HelpDialog open={helpDialogOpen} onClose={closeDialogs} />
      <AboutDialog open={aboutDialogOpen} onClose={closeDialogs} />
    </>
  );
};

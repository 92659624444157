/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useStatistics } from '../hooks/useStatistics';

import { ChatBubble } from '../components/ChatBubble';
import { getFormalName } from '../util/professional';
import { RootState } from '../types/graph';
import { Questionnaire } from './Questionnaire';
import { pages } from '../util/graph';
import { useUpdateQuestionnaire } from '../hooks/questionnaire.hooks';
import { nextPage } from '../reducers/graph';

export const CheckIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const professional = useSelector(
    (state: RootState) => state.consultation.professional
  );

  const recordStatistics = useStatistics();

  const { updateQuestionnaire, isLoading } = useUpdateQuestionnaire({
    checkedIn: true,
    onSuccess: () => {
      dispatch(nextPage());
      recordStatistics({ section: 'CHECK_IN' });
      navigate(`/professional?checkin=true`);
    },
  });

  const onNext = useCallback(() => {
    updateQuestionnaire();
  }, [updateQuestionnaire]);

  if (!professional) return <Navigate to="/" />;

  return (
    <Questionnaire
      blockPrevious
      nextLabel="Enregistrement"
      page={pages[0]}
      submissionLoading={isLoading}
      onSubmit={onNext}
      topElement={
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 40,
            paddingBottom: 12,
            gap: 30,
          }}
        >
          <ChatBubble professional={professional} size={60} variant="light">
            <b>{`Prévenez ${getFormalName(professional)} de votre arrivée`}</b>
          </ChatBubble>
        </div>
      }
    />
  );
};

import { gql } from '@apollo/client';

export const ResponseLabelFields = gql`
  fragment ResponseLabelFields on Response {
    labelsConnection {
      edges {
        condition
        node {
          value
        }
      }
    }
  }
`;

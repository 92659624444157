/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import { useSelector } from 'react-redux';
import config from '../config';

import { RootState } from '../types/graph';

import { client } from '../util/client';
import { useVersion } from './useVersion';

export interface QuestionnaireStatistic {
  section: string;
  version: string;
  numberOfQuestions?: number;
  numberOfResponses?: number;
}

export const useStatistics = () => {
  const sessionId = useSelector((state: RootState) => state.sessionId);
  const demoPractice = useSelector((state: RootState) =>
    Boolean(state.consultation.practice?.demo)
  );
  const [startTime, setStartTime] = useState(new Date());
  const version = useVersion();

  const demo = config.env.demo || demoPractice;

  const recordStatistics = (
    data: Omit<QuestionnaireStatistic, 'version'>,
    disabled?: boolean
  ) => {
    if (demo || disabled) return;

    return client
      .post('/questionnaireStatistics/v2', {
        sessionId,
        data: [
          {
            startTime,
            endTime: new Date(),
            demo: demo || disabled,
            version: version?.toString(),
            ...data,
          },
        ],
      })
      .finally(() => setStartTime(new Date()));
  };

  return recordStatistics;
};

/** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom';

import { IconButton, Typography, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { ReactComponent as ShortLogo } from '../assets/ShortLogo.svg';

import { RootState } from '../types/graph';
import { useSelector } from 'react-redux';
import { ProgressBar } from './ProgressBar';
import { useTheme } from '@emotion/react';
import { HeaderContentProps } from './Header';

export const QuestionnaireHeader = ({
  menu,
  onOpenMenu,
}: HeaderContentProps) => {
  const theme = useTheme();

  const currentPage = useSelector(
    (state: RootState) => state.graph.currentPage
  );

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div
      css={{
        display: 'flex',
        position: 'unset',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          height: '100%',
          width: 'calc(100% - 24px)',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: theme.palette.white,
          zIndex: 1,
          paddingLeft: 12,
          paddingRight: 12,
          '.filler': { display: 'none' },
          [theme.breakpoints.up('md')]: {
            '.menuIcon': { display: 'none' },
            '.filler': { display: 'block' },
          },
        }}
      >
        <Link to="/">
          <ShortLogo
            css={{
              height: 21,
              path: { fill: theme.palette.primary.main },
              padding: 8,
            }}
          />
        </Link>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.primary.main,
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {currentPage}
        </Typography>
        <IconButton
          className="menuIcon"
          css={{
            color: theme.palette.primary.main,
          }}
          size="large"
          onClick={onOpenMenu}
        >
          <MenuIcon />
        </IconButton>
        <div className="filler" />
      </div>
      {isMobile && <ProgressBar />}
      {menu}
    </div>
  );
};

/** @jsxImportSource @emotion/react */

import { FormEvent, useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Breadcrumbs,
  Chip,
  TextField,
  Typography,
} from '@mui/material';

import { useCheckCondition } from '../hooks/useCheckCondition';
import { useReport } from '../hooks/questionnaire.hooks';

import { RootState } from '../types/graph';

import Button from '@mui/material/Button';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Value } from '../types/global';
import { useEvaluate } from '../hooks/useEvaluate';

const Report = () => {
  const report = useReport();

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Compte rendu
      </AccordionSummary>
      <AccordionDetails>
        {report.sort().map((r, i) => (
          <Typography fontFamily="monospace" key={i}>
            {r}
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const ConditionChecker = () => {
  const [condition, setCondition] = useState('');
  const [conditionTrue, setConditionTrue] = useState<boolean | undefined>();

  const checkCondition = useCheckCondition();

  const onCheckCondition = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setConditionTrue(checkCondition(condition));
    },
    [condition, checkCondition]
  );

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Verificateur de condition
      </AccordionSummary>
      <AccordionDetails>
        <form
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 24,
            paddingBottom: 24,
          }}
          onSubmit={onCheckCondition}
        >
          <TextField
            fullWidth
            multiline
            label="Condition"
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
          />
          <Button type="submit">Vérifier</Button>
          {conditionTrue !== undefined && (
            <Chip
              size="medium"
              label={conditionTrue ? 'Condition vraie' : 'Condition fausse'}
              color={conditionTrue ? 'success' : 'error'}
              icon={conditionTrue ? <DoneIcon /> : <CloseIcon />}
            />
          )}
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

const Evaluator = () => {
  const [clause, setClause] = useState('');
  const [result, setResult] = useState<Value>();

  const evaluate = useEvaluate();

  const onCheckCondition = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setResult(evaluate(clause));
    },
    [clause, evaluate]
  );

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Évaluateur d'expression
      </AccordionSummary>
      <AccordionDetails>
        <form
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 24,
            paddingBottom: 24,
          }}
          onSubmit={onCheckCondition}
        >
          <TextField
            fullWidth
            multiline
            label="Expression"
            value={clause}
            onChange={(e) => setClause(e.target.value)}
          />
          <Button type="submit">Vérifier</Button>
        </form>
        <TextField fullWidth disabled value={result} />
      </AccordionDetails>
    </Accordion>
  );
};

const Queue = () => {
  const blocks = useSelector((state: RootState) => state.questionnaire.blocks);
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Queue</AccordionSummary>
      <AccordionDetails>
        {blocks.map((block, i) => (
          <Alert
            key={block[0]}
            severity={
              blocks.length === 1
                ? 'success'
                : block.length === 1
                ? 'warning'
                : 'info'
            }
          >
            <Breadcrumbs key={i}>
              {block.map((extId, j) => (
                <Typography key={i + ',' + j}>{extId}</Typography>
              ))}
            </Breadcrumbs>
          </Alert>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export const Debug = () => {
  return (
    <div css={{ margin: 24 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Debug
      </Typography>
      <Queue />
      <Report />
      <ConditionChecker />
      <Evaluator />
    </div>
  );
};

import difference from 'lodash/difference';
import partition from 'lodash/partition';
import pull from 'lodash/pull';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';
import remove from 'lodash/remove';
import { v4 as uuid } from 'uuid';
import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import {
  checkCondition,
  evaluate,
  Page,
  pages,
  sections,
  sortQuestions,
  standardValue,
  valueRegex,
} from '../util/graph';
import {
  FormulaStore,
  GraphState,
  RecordedResponses,
  RootState,
} from '../types/graph';
import {
  Assertion,
  Formula,
  InsertableQuestion,
  Patient,
  Practice,
  Professional,
  UserValue,
  ViewableQuestion,
} from '../types/global';

interface QueueModifications {
  toInsert: InsertableQuestion[];
  toDelete: string[];
}

const options = ['COMPACT', 'ALERTES'];

const initialAssertions = [
  "[Message: #‹]-(06.a_lieu_emission)->[Lieu]-(01.DESCR)->[Salle d'Attente]-(PART_OF)->[Cabinet Médical: #c]",
  '[Médecin: #z‹]-(a_spécialité)->[Médecine Générale]',
  '[Médecin: #z‹]-(a_habilitation)->[IVG médicamenteuse]',
];

export const initialState: RootState = {
  graph: {
    values: {},
    assertions: initialAssertions,
    currentPage: pages[0],
    options,
  },
  questionnaire: {
    maxPage: 0,
    queue: [],
    pageAssignments: {},
    insertedBy: {},
    activeQueue: [],
    blocks: [],
    validQuestions: [],
  },
  kb: {
    questions: {},
    responses: {},
    formulas: {},
  },
  consultation: {},
  userResponses: {},
  sessionId: uuid(),
  questionnaireId: uuid(),
};

export const recordResponses =
  createAction<RecordedResponses>('RECORD_RESPONSES');
export const nextPage = createAction('NEXT_PAGE');
export const previousPage = createAction('PREVIOUS_PAGE');
export const changePage = createAction<string>('CHANGE_PAGE');
export const insertQuestions =
  createAction<InsertableQuestion[]>('INSERT_QUESTIONS');
export const updateQuestions =
  createAction<ViewableQuestion[]>('UPDATE_QUESTIONS');
export const updateFormulas = createAction<Formula[]>('UPDATE_FORMULAS');

export const updatePatient =
  createAction<Omit<Patient, 'id'>>('UPDATE_PATIENT');
export const clearPatient = createAction<Patient>('CLEAR_PATIENT');
export const updatePractice = createAction<Practice>('UPDATE_PRACTICE');
export const updateProfessional = createAction<Professional>(
  'UPDATE_PROFESSIONAL'
);
export const updateReferrer = createAction<string>('UPDATE_REFERRAL_SOURCE');
export const updateQuestionnaireId = createAction<string>(
  'UPDATE_QUESTIONNAIRE_ID'
);
export const setWaitingRoom = createAction<boolean>('SET_WAITING_ROOM');

const getQueueModifications = (
  state: RootState,
  responseId: string,
  removalMode = false
) => {
  const { insertsQuestions, deletesQuestions } = state.kb.responses[responseId];

  return removalMode === true
    ? {
        toInsert: deletesQuestions,
        toDelete: insertsQuestions.map((q) => q.extId),
      }
    : {
        toInsert: insertsQuestions,
        toDelete: deletesQuestions.map((q) => q.extId),
      };
};

const unassert = (graph: GraphState, assertionStrings: string[]) => {
  const toRemove = assertionStrings.map((a: string) =>
    a.split('-<EVALUE>->')[0].replace(valueRegex, standardValue)
  );
  pull(graph.assertions, ...toRemove);
  graph.values = omit(graph.values, ...toRemove);
};

const assert = (
  graph: GraphState,
  formulas: FormulaStore,
  assertionStrings: string[],
  userValue: UserValue
) => {
  assertionStrings.forEach((a: string) => {
    const [assertion, clause] = a.split('-<EVALUE>->');

    let value: UserValue | null | undefined;
    const cleaned = assertion.replace(valueRegex, (_, match, unit) => {
      if (clause) {
        const evaluatedValue = evaluate(graph.values, formulas, clause);
        if (evaluatedValue !== undefined)
          value = { value: evaluate(graph.values, formulas, clause) };
        else value = null;
      } else if (['#?', '?', '*'].includes(match)) {
        value = userValue;
      } else {
        value = { value: !isNaN(match) ? Number(match) : match };
      }

      if (value && !value.unit && unit) value.unit = { plural: unit };

      return standardValue;
    });
    if (value !== null) {
      graph.assertions.push(cleaned);
      if (value !== undefined) graph.values[cleaned] = value;
    }
  });
};

const getValidAssertions = (
  graph: GraphState,
  formulas: FormulaStore,
  assertions: Assertion[]
) => {
  const validAssertions = assertions
    .map((assertion) => assertion.value)
    .map((assertion) => {
      const [assertionString, conditionString] =
        assertion.split('-<CONDITION>->');
      if (
        conditionString &&
        !checkCondition(graph, formulas, conditionString)
      ) {
        return '';
      }
      return assertionString;
    })
    .filter((a) => a);

  const [evaluatedAssertions, standardAssertions] = partition(
    validAssertions,
    (assertion) => assertion.includes('<EVALUE>')
  );

  return [...standardAssertions, ...evaluatedAssertions];
};

const removeResponse = (state: RootState, responseId: string) => {
  const response = state.kb.responses[responseId];

  unassert(
    state.graph,
    getValidAssertions(state.graph, state.kb.formulas, response.assertions)
  );

  if (state.userResponses[response.question])
    delete state.userResponses[response.question][responseId];

  const { toInsert, toDelete } = getQueueModifications(state, responseId, true);

  response.insertsQuestions.forEach((q) => {
    if (state.userResponses[q.extId])
      Object.keys(
        pickBy(state.userResponses[q.extId], (r) => r !== false)
      ).forEach((extId) => {
        const res = removeResponse(state, extId);
        toInsert.push(...res.toInsert);
        toDelete.push(...res.toDelete);
      });
  });

  return {
    toInsert,
    toDelete,
  };
};

const addResponse = (
  state: RootState,
  responseId: string,
  value: UserValue
) => {
  const response = state.kb.responses[responseId];
  let remainingAssertions = [...response.assertions];

  let assertions = getValidAssertions(
    state.graph,
    state.kb.formulas,
    response.assertions
  );
  unassert(state.graph, assertions);

  assertions = getValidAssertions(
    state.graph,
    state.kb.formulas,
    response.assertions
  );

  while (assertions.length > 0) {
    remove(remainingAssertions, (a) =>
      assertions.includes(a.value.split('-<CONDITION>->')[0])
    );

    assert(state.graph, state.kb.formulas, assertions, value);

    assertions = getValidAssertions(
      state.graph,
      state.kb.formulas,
      remainingAssertions
    );
  }

  if (state.userResponses[response.question])
    state.userResponses[response.question][responseId] = value.value;
  else state.userResponses[response.question] = { [responseId]: value.value };

  return getQueueModifications(state, responseId, false);
};

const updateQueue = (
  state: RootState,
  { toInsert, toDelete }: QueueModifications,
  currentQuestion?: string,
  currentPage?: string
) => {
  toInsert.forEach((q) => {
    if (currentQuestion)
      state.questionnaire.insertedBy[q.extId] = currentQuestion;
    if (currentPage) state.questionnaire.pageAssignments[q.extId] = currentPage;
  });
  return uniq(
    state.questionnaire.queue
      .filter((extId) => !toDelete.includes(extId))
      .concat(toInsert.map((q) => q.extId))
      .filter((extId) => extId)
  );
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(
      recordResponses.type,
      (state, action: PayloadAction<RecordedResponses>) => {
        state.questionnaire.currentQuestion = action.payload.questionId;

        const [toAdd, toRemove] = partition(
          action.payload.responses,
          (r) => r.value.value !== false
        );

        const queueModifications: QueueModifications = {
          toInsert: [],
          toDelete: [],
        };

        toRemove.forEach((r) => {
          const result = removeResponse(state, r.extId);
          queueModifications.toInsert.push(...result.toInsert);
          queueModifications.toDelete.push(...result.toDelete);
        });

        toAdd.forEach((r) => {
          const result = addResponse(state, r.extId, r.value);
          queueModifications.toInsert.push(...result.toInsert);
          queueModifications.toDelete.push(...result.toDelete);
        });

        state.graph.assertions = uniq(state.graph.assertions);

        state.questionnaire.queue = updateQueue(
          state,
          queueModifications,
          action.payload.questionId,
          action.payload.currentPage
        );
      }
    )
    .addCase(changePage.type, (state, action: PayloadAction<Page>) => {
      if (pages.includes(action.payload))
        state.graph.currentPage = action.payload;
    })
    .addCase(nextPage.type, (state) => {
      const index = pages.indexOf(state.graph.currentPage);
      if (index < pages.length - 1) {
        state.graph.currentPage = pages[index + 1];
        if (index + 1 > state.questionnaire.maxPage)
          state.questionnaire.maxPage = index + 1;
      }
    })
    .addCase(previousPage.type, (state) => {
      const index = pages.indexOf(state.graph.currentPage);
      if (index > 0) state.graph.currentPage = pages[index - 1];
    })
    .addCase(
      insertQuestions.type,
      (state, action: PayloadAction<InsertableQuestion[]>) => {
        state.questionnaire.queue = updateQueue(state, {
          toInsert: [...action.payload],
          toDelete: [],
        });
      }
    )
    .addCase(
      updateQuestions.type,
      (state, action: PayloadAction<ViewableQuestion[]>) => {
        const next = action.payload.map((q) => {
          state.kb.questions[q.extId] = q;
          q.responses.forEach((r) => {
            state.kb.responses[r.extId] = r;
          });
          return q.extId;
        });

        const removedQuestions = difference(
          state.questionnaire.validQuestions,
          next
        );

        const queueModifications: QueueModifications = {
          toInsert: [],
          toDelete: [],
        };

        removedQuestions.forEach((extId) =>
          state.kb.questions[extId]?.responses?.forEach((r) => {
            const result = removeResponse(state, r.extId);
            queueModifications.toInsert.push(...result.toInsert);
            queueModifications.toDelete.push(...result.toDelete);
          })
        );

        state.questionnaire.queue = updateQueue(state, queueModifications);

        state.questionnaire.validQuestions = next;

        const blocks = sortQuestions(action.payload, state.questionnaire.queue);

        state.questionnaire.blocks = blocks.map((t) => t.map((q) => q.extId));

        state.questionnaire.activeQueue = blocks
          .flat()
          .filter((q) =>
            q.section
              ? sections[state.graph.currentPage]?.includes(q.section)
              : state.questionnaire.pageAssignments[q.extId] ===
                state.graph.currentPage
          )
          .map((q) => q.extId);
      }
    )
    .addCase(updateFormulas.type, (state, action: PayloadAction<Formula[]>) => {
      state.kb.formulas = action.payload.reduce<RootState['kb']['formulas']>(
        (acc, formula) => {
          acc[formula.name] = formula.value;
          return acc;
        },
        {}
      );
    })
    .addCase(updatePatient.type, (state, action: PayloadAction<Patient>) => {
      state.consultation.patient = action.payload;
    })
    .addCase(clearPatient.type, (state, action: PayloadAction<Patient>) => {
      delete state.consultation.patient;
    })
    .addCase(updatePractice.type, (state, action: PayloadAction<Practice>) => {
      state.consultation.practice = action.payload;
    })
    .addCase(updateReferrer.type, (state, action: PayloadAction<string>) => {
      state.referrer = action.payload;
    })
    .addCase(
      updateQuestionnaireId.type,
      (state, action: PayloadAction<string>) => {
        state.questionnaireId = action.payload;
      }
    )
    .addCase(
      updateProfessional.type,
      (state, action: PayloadAction<Professional>) => {
        state.consultation.professional = action.payload;
        if (action.payload.gender) {
          const gender =
            action.payload.gender === 'MALE'
              ? 'Masculin'
              : action.payload.gender === 'FEMALE'
              ? 'Féminin'
              : 'Autre';
          state.graph.assertions.push(
            `[Médecin: #z‹]-(02.a_genre)->[Genre: ${gender}]`
          );
        }
        if (!action.payload.allowAdvancedPatientAccess) {
          state.graph.options.push('SALLE_ATTENTE');
        }
      }
    )
    .addCase(setWaitingRoom.type, (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.graph.options.push('SALLE_ATTENTE');
      } else {
        pull(state.graph.options, 'SALLE_ATTENTE');
      }
    })
    .addCase(PURGE, (state) => ({
      ...initialState,
      sessionId: uuid(),
      referrer: state.referrer,
      consultation: {
        practice: state.consultation.practice,
        professional: state.consultation.professional,
      },
    }));
});

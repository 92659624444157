import { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { Input, InputOnBlurEvent } from './Input';

interface ResponseNumberFieldProps {
  value?: string;
  label: string;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  unit?: string;
  disabled?: boolean;
  integer?: boolean;
}

export const ResponseNumberField = ({
  value,
  label,
  min,
  max,
  unit,
  onChange,
  disabled,
  integer = false,
}: ResponseNumberFieldProps) => {
  const [error, setError] = useState(false);

  const onBlur = useCallback(
    (e: InputOnBlurEvent) => {
      const value = e.target.value;
      if (
        (value !== '' && min !== undefined && Number(value) < min) ||
        (max !== undefined && Number(value) > max)
      )
        setError(true);
      onChange(Number(value));
    },
    [max, min, onChange]
  );

  return (
    <Input
      type="number"
      placeholder={label}
      defaultValue={value}
      onChange={() => setError(false)}
      onBlur={onBlur}
      error={error}
      helperText={error ? `Veuillez vérifier la valeur saisie` : undefined}
      disabled={disabled}
      inputMode={integer ? 'numeric' : 'decimal'}
      step={integer ? '1' : '0.1'}
      min={min}
      max={max}
      halfWidth
      endItem={
        unit && (
          <Typography
            sx={(theme) => ({ color: theme.palette.gray, fontSize: 14 })}
          >
            {unit}
          </Typography>
        )
      }
    />
  );
};

import { Value, ViewableQuestion, ViewableResponse } from '../types/global';
import { MoreButton } from './MoreButton';

import { ResponseButton } from './ResponseButton';
import { ResponseDateField } from './ResponseDateField';
import { ResponseDateTimeField } from './ResponseDateTimeField';
import { ResponseDOBField } from './ResponseDOBField';
import { ResponseNumberField } from './ResponseNumberField';
import { ResponseOtherField } from './ResponseOtherField';
import { ResponseTextField } from './ResponseTextField';
import { ResponseTimeField } from './ResponseTimeField';

interface ResponseProps {
  response: ViewableResponse;
  onChange: (value: Value) => void;
  value: Value;
  questionLabel: ViewableQuestion['label'];
  disabled?: boolean;
  multiselect?: boolean;
}

export const Response = ({
  response,
  onChange,
  value,
  multiselect,
  questionLabel,
  disabled,
}: ResponseProps) => {
  switch (response.format) {
    case 'INTEGER':
      return (
        <ResponseNumberField
          label={response.label}
          value={value?.toString()}
          onChange={onChange}
          unit={response.unit?.plural}
          min={response.minimum}
          max={response.maximum}
          disabled={disabled}
          integer
        />
      );
    case 'Valeur':
    case 'NUMBER':
    case 'DECIMAL':
      return (
        <ResponseNumberField
          label={response.label}
          value={value?.toString()}
          onChange={onChange}
          unit={response.unit?.plural}
          min={response.minimum}
          max={response.maximum}
          disabled={disabled}
        />
      );
    case 'DATETIME':
      return (
        <ResponseDateTimeField
          label={response.label}
          value={(value as number) ?? undefined}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case 'DATE':
      return (
        <ResponseDateField
          label={response.label}
          value={(value as number) ?? undefined}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case 'TIME':
      return (
        <ResponseTimeField
          label={response.label}
          value={(value as number) ?? undefined}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case 'DATE_OF_BIRTH':
      return (
        <ResponseDOBField
          value={(value as number) ?? undefined}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case 'Field':
    case 'TEXT':
      return (
        <ResponseTextField
          label={response.label}
          value={value?.toString()}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case 'multiLineText':
    case 'MULTILINE_TEXT':
      return (
        <ResponseTextField
          label={response.label}
          value={value?.toString()}
          onChange={onChange}
          multiline
          disabled={disabled}
        />
      );
    case 'OTHER_TEXT':
      return (
        <ResponseOtherField
          questionLabel={questionLabel}
          label={response.label}
          value={value?.toString()}
          onChange={onChange}
          multiselect={multiselect}
        />
      );
    case 'roundRect-more':
      return <MoreButton label={response.label} onChange={onChange} />;
    default:
      return (
        <ResponseButton
          selected={Boolean(value)}
          onChange={onChange}
          label={response.label ?? ''}
          multiselect={multiselect}
        />
      );
  }
};

/** @jsxImportSource @emotion/react */

import { ReactNode } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { IconButton } from '@mui/material';

export interface NavigationBarProps {
  onNext?: () => void;
  onPrevious?: () => void;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

export const NavigationBar = ({
  label,
  onNext,
  onPrevious,
  startIcon,
  endIcon,
  disabled,
  loading,
}: NavigationBarProps) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 20,
      marginRight: 20,
      marginLeft: 20,
      gap: 24,
      width: 'calc(100% - 40px)',
    }}
  >
    {onPrevious && (
      <IconButton
        sx={(theme) => ({
          borderRadius: 3,
          paddingTop: 1.5,
          paddingBottom: 1.5,
          textTransform: 'none',
          color: theme.palette.primary.main,
        })}
        size="large"
        onClick={onPrevious}
      >
        <ArrowBackOutlinedIcon />
      </IconButton>
    )}
    {onNext && (
      <LoadingButton
        type="submit"
        disabled={disabled || loading}
        color="secondary"
        sx={{
          flexGrow: 1,
          borderRadius: 3,
          paddingTop: 1.5,
          paddingBottom: 1.5,
          textTransform: 'none',
          justifyContent: 'space-between',
          '&, :hover': {
            boxShadow: 'none',
          },
        }}
        variant="contained"
        size="large"
        onClick={(e) => {
          e.preventDefault();
          onNext && onNext();
        }}
        startIcon={startIcon ?? <div />}
        endIcon={
          endIcon ?? ((onPrevious && <ArrowForwardOutlinedIcon />) || <div />)
        }
        loading={loading}
      >
        {label ?? 'Continuer'}
      </LoadingButton>
    )}
  </div>
);

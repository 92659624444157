import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import debounce from 'lodash/debounce';

type ScrollContextType = [number, () => void];

export const ScrollContext = createContext<ScrollContextType>([0, () => {}]);

const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

export const ScrollProvider = ({ children }: { children: ReactNode }) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const detectScrollProgress = debounce((event) => {
    const target = event.target;

    const scrolled = target.scrollTop;
    const total = target.scrollHeight - target.offsetHeight;

    const scrollPercent = scrolled / total;

    setScrollProgress(clamp(Math.round(scrollPercent * 100), 0, 100));
  }, 10);

  const scrollToTop = useCallback(() => {
    const main = document.querySelector('main');
    if (main) {
      setScrollProgress(0);
      main.scroll({ top: 0 });
    }
  }, [setScrollProgress]);

  useEffect(() => {
    const main = document.querySelector('main');
    if (main) {
      main.addEventListener('scroll', detectScrollProgress);
      return () => main.removeEventListener('scroll', detectScrollProgress);
    }
  }, [detectScrollProgress]);

  return (
    <ScrollContext.Provider value={[scrollProgress, scrollToTop]}>
      {children}
    </ScrollContext.Provider>
  );
};

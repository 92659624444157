/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';

import { SubheaderProps } from '../components/Subheader';

interface QuestionnaireLayoutProps extends SubheaderProps {
  children: ReactNode;
  topElement?: ReactNode;
  bottomElement?: ReactNode;
}

export const QuestionnaireLayout = ({
  children,
  topElement,
  bottomElement,
}: QuestionnaireLayoutProps) => (
  <div
    css={{
      width: '100%',
      minHeight: 'calc(100% - 24px)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 24,
    }}
  >
    {topElement}
    <div
      css={{
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </div>
    {bottomElement}
  </div>
);

import React, { ErrorInfo, ReactNode } from 'react';
import { ErrorPage } from './ErrorPage';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    isError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { isError: false };
    }

    static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo) {
        return { isError: true, error, errorInfo };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {}

    render() {
        if (this.state.isError) {
            return <ErrorPage error={this.state.error} errorInfo={this.state.errorInfo} />;
        }

        return this.props.children;
    }
}

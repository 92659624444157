/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';

import { Card } from '../components/Card';
import { NavigationBar, NavigationBarProps } from './NavigationBar';

export interface FormCardProps extends NavigationBarProps {
  children: ReactNode;
  topElement?: ReactNode;
  bottomElement?: ReactNode;
}

export const FormCard = ({
  children,
  onNext,
  topElement,
  bottomElement,
  ...props
}: FormCardProps) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      onNext && onNext();
    }}
    css={(theme) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.background.default,
    })}
  >
    <div
      css={{
        flexGrow: 1,
        minHeight: 0,
        marginTop: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {topElement}
      <Card
        css={{
          overflowY: 'auto',
        }}
      >
        {children}
      </Card>
    </div>
    <div
      css={{
        justifySelf: 'flex-end',
        marginTop: 12,
        marginBottom: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
      }}
    >
      {bottomElement}
      <NavigationBar onNext={onNext} {...props} />
    </div>
  </form>
);

import { gql } from '@apollo/client';
import { ResponseLabelFields } from './response.fragments';

export const QuestionLabelFields = gql`
  fragment QuestionLabelFields on Question {
    labelsConnection {
      edges {
        condition
        node {
          value
        }
      }
    }
  }
`;

export const ALL_QUESTION_FIELDS = gql`
  ${QuestionLabelFields}
  ${ResponseLabelFields}
  fragment AllQuestionFields on Question {
    id
    extId
    format
    section
    multipleChoice
    paginateResponses
    required
    ...QuestionLabelFields
    commentsConnection {
      edges {
        condition
        node {
          id
          value
        }
      }
    }
    afterConnection {
      edges {
        condition
        node {
          extId
        }
      }
    }
    responses {
      id
      extId
      format
      multipleChoice
      exclusive
      assertions {
        id
        value
      }
      ...ResponseLabelFields
      insertsReponses {
        extId
      }
      insertsQuestions {
        extId
        afterConnection {
          edges {
            condition
            node {
              extId
            }
          }
        }
      }
      deletesQuestions {
        extId
        afterConnection {
          edges {
            condition
            node {
              extId
            }
          }
        }
      }
      question {
        extId
      }
      after {
        extId
      }
      reportsConnection {
        edges {
          condition
          node {
            id
            value
          }
        }
      }
      unitConnection {
        edges {
          condition
          node {
            plural
            singular
          }
        }
      }
      minimumConnection {
        edges {
          condition
          node {
            value
          }
        }
      }
      maximumConnection {
        edges {
          condition
          node {
            value
          }
        }
      }
      incrementConnection {
        edges {
          condition
          node {
            value
          }
        }
      }
    }
  }
`;

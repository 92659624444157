/** @jsxImportSource @emotion/react */
import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useRequestDemo } from '../hooks/useRequestDemo';

import {
  Grow,
  TextField,
  Typography,
  useTheme,
  Dialog,
  useMediaQuery,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useSelector } from 'react-redux';
import { RootState } from '../types/graph';
import { Card } from './Card';

import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

export const DemoRequest = () => {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(true);
  const [focused, setFocused] = useState(false);

  const enableDialog = useMediaQuery(theme.breakpoints.down('sm'));

  const referrer = useSelector(
    (state: RootState) => state.consultation.referrer
  );

  const onFocus = useCallback(() => setFocused(true), []);
  const onBlur = useCallback(() => setFocused(false), []);

  const {
    mutate: requestDemo,
    isLoading,
    isSuccess,
  } = useRequestDemo({ onSuccess: () => onBlur() });

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setValid(e.target.validity?.valid);
  }, []);

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      requestDemo({ email, referrer });
    },
    [email, referrer, requestDemo]
  );

  const innerContent = (
    <Card
      css={{
        position: 'relative',
        marginLeft: 24,
        marginRight: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 8,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.light,
      }}
      onClick={enableDialog ? onFocus : undefined}
    >
      <ChatOutlinedIcon
        css={{
          position: 'absolute',
          color: theme.palette.primary.main,
          opacity: 0.2,
          zIndex: 0,
          height: 90,
          width: 90,
          top: 12,
          right: 12,
        }}
      />

      <Typography
        variant="h5"
        sx={{
          textAlign: 'left',
          fontSize: 16,
          fontWeight: 500,
        }}
      >
        Restons en contact
      </Typography>
      {(focused || !enableDialog) && (
        <Typography
          sx={{
            textAlign: 'justified',
            fontSize: 12,
            marginRight: 5,
            marginBottom: 1,
          }}
        >
          {`Cette démo vous a plu\u00A0? Envie d'en apprendre davantage sur l'assistant médical numérique d'Aldebaran\u00A0? Restons en contact\u00A0!`}
        </Typography>
      )}
      <form
        onSubmit={onSubmit}
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {isSuccess ? (
          <Grow in>
            <Typography
              variant="body1"
              sx={{ flexGrow: 1, fontStyle: 'italic' }}
            >
              Bien reçu, à bientôt !
            </Typography>
          </Grow>
        ) : (
          <TextField
            variant="filled"
            size="small"
            sx={{ flexGrow: 1, backgroundColor: 'white' }}
            label="Adresse e-mail"
            placeholder="jean@gmail.com"
            value={email}
            onChange={onChange}
            onClick={
              enableDialog && focused ? (e) => e.stopPropagation() : undefined
            }
            onFocus={enableDialog ? undefined : onFocus}
            onBlur={enableDialog ? undefined : onBlur}
            autoFocus={enableDialog && focused}
            inputProps={{
              autofill: enableDialog && focused ? 'email' : 'none',
              type: 'email',
            }}
          />
        )}
        <button
          type="submit"
          disabled={!valid || isLoading}
          css={{ all: 'unset', cursor: 'pointer', padding: 8, marginLet: 24 }}
        >
          {isSuccess ? <CheckIcon /> : <ArrowForwardOutlinedIcon />}
        </button>
      </form>
    </Card>
  );

  return (
    <>
      {innerContent}
      {enableDialog && (
        <Dialog open={focused} onClose={onBlur} fullScreen>
          <div
            css={{
              height: '50%',
              paddingTop: '20%',
              paddingBottom: 16,
            }}
            onClick={onBlur}
          >
            {innerContent}
          </div>
        </Dialog>
      )}
    </>
  );
};

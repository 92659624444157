/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';

import { Skeleton, Typography } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  setWaitingRoom,
  updatePractice,
  updateProfessional,
} from '../reducers/graph';
import { useStatistics } from '../hooks/useStatistics';

import { ChatBubble } from '../components/ChatBubble';
import { ProfessionalAvatar } from '../components/ProfessionalAvatar';
import { getFormalName, getFromName } from '../util/professional';
import { NavigationBar } from '../components/NavigationBar';
import { RootState } from '../types/graph';
import { useProfessional } from '../hooks/useProfessional';
import { Professional } from '../types/global';

const LoadingPlaceholder = () => (
  <>
    <Skeleton variant="rectangular" sx={{ mb: 2 }} />
    <Skeleton variant="text" />
    <Skeleton variant="text" />
  </>
);

export const ProfessionalLanding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [searchParams] = useSearchParams();

  const postCheckIn = Boolean(searchParams.get('checkin'));

  const recordStatistics = useStatistics();

  const professional = useSelector(
    (state: RootState) => state.consultation.professional
  );

  const { isLoading, error } = useProfessional(slug ?? professional?.slug, {
    onSuccess: (professional: Professional) => {
      if (!postCheckIn) dispatch(setWaitingRoom(false));
      dispatch(updatePractice(professional.practice));
      dispatch(updateProfessional(professional));
    },
  });

  const onNext = useCallback(() => {
    if (professional) {
      dispatch(updateProfessional(professional));
      recordStatistics({ section: 'PROFESSIONAL' });
      navigate('/questionnaire');
    }
  }, [professional, dispatch, recordStatistics, navigate]);

  return (
    <main
      css={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {!professional ? (
        isLoading ? (
          <LoadingPlaceholder />
        ) : (
          <>
            <Typography variant="h5">
              Impossible de trouver votre professional
            </Typography>
            {error?.message && <Typography>{error.message}</Typography>}
          </>
        )
      ) : (
        <>
          <div
            css={{
              flexShrink: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 24,
              gap: 30,
              textAlign: 'center',
            }}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontWeight: 'medium',
              })}
              variant="h5"
            >
              Assistant médical
              <br />
              {getFromName(professional)}
            </Typography>
            <ProfessionalAvatar professional={professional} size={120} />
          </div>
          <div
            css={{
              flexGrow: 1,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 8,
              paddingBottom: 8,
              gap: 12,
            }}
          >
            {postCheckIn ? (
              <>
                <ChatBubble assistant order={1}>
                  <b>
                    Merci, le {getFormalName(professional)} a été prévenu de
                    votre arrivée
                  </b>
                </ChatBubble>
                <ChatBubble assistant order={2}>
                  {professional.gender === 'FEMALE' ? 'Elle' : 'Il'} vous
                  demande de{' '}
                  <b>
                    compléter un questionnaire avant de vous prendre en charge
                  </b>
                </ChatBubble>
              </>
            ) : (
              <ChatBubble assistant order={3}>
                <b>
                  Votre médecin vous demande de répondre aux questions qui vont
                  suivre avant de vous prendre en charge.
                </b>
              </ChatBubble>
            )}
            <ChatBubble assistant order={4}>
              Le {getFormalName(professional)} recevra vos réponses à chaque
              étape du questionnaire
            </ChatBubble>
            <ChatBubble assistant order={5}>
              Ce questionnaire dure <b>en moyenne 5 minutes</b> et porte sur vos
              besoins de santé, mais également sur votre histoire médicale et de
              la prévention
            </ChatBubble>
            {professional.welcomeMessage && (
              <ChatBubble order={4}>
                <b>{professional.welcomeMessage}</b>
              </ChatBubble>
            )}
          </div>
          <div css={{ paddingBottom: 24 }}>
            <NavigationBar
              onNext={onNext}
              label="Préparer votre consultation"
            />
          </div>
        </>
      )}
    </main>
  );
};

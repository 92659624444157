import { createTheme } from '@mui/material';

const colors = {
  coral: '#fa5447',
  darkPurple: '#383354',
  darkGray: '#666666',
  gray: '#AAA9B5;',
  lightGray: '#f0f0f0',
  backgroundGray: '#EBEBEE',
  ultraLightGray: 'rgba(245, 245, 245, 1)',
  black: '#363636',
  purple: '#8066ed',
  lightPurple: '#D7D6DD',
  white: '#ffffff',
  shadow: 'rgba(54, 54, 54, 0.2)',
  error: '#D8000C',
  success: '##18C5B4',
  green: '#5E9DA3',
  lightGreen: '#F4F8F9',
  disabled: 'gray',
};

export const theme = createTheme({
  palette: {
    ...colors,
    primary: {
      main: colors.darkPurple,
      light: colors.purple,
    },
    secondary: {
      main: colors.green,
    },
    error: {
      main: colors.error,
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.coral,
    },
  },
  typography: (palette) => ({
    fontFamily: 'Montserrat',
    inherit: {
      color: palette.primary.main,
    },
  }),
});

/** @jsxImportSource @emotion/react */
import { Fade, Typography, TypographyProps, FadeProps } from '@mui/material';
import { Professional } from '../types/global';
import { AssistantAvatar } from './AssistantAvatar';
import { ProfessionalAvatar } from './ProfessionalAvatar';

interface ChatBubbleProps {
  children: TypographyProps['children'];
  professional?: Professional;
  assistant?: boolean;
  show?: FadeProps['in'];
  order?: number;
  size?: number;
  variant?: 'dark' | 'light';
}

export const ChatBubble = ({
  children,
  professional,
  assistant,
  show = true,
  order = 0,
  size = 24,
  variant,
}: ChatBubbleProps) => (
  <Fade in={show} timeout={500} style={{ transitionDelay: `${200 * order}ms` }}>
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
      }}
    >
      {assistant ? (
        <AssistantAvatar size={size} />
      ) : (
        <ProfessionalAvatar professional={professional} size={size} />
      )}
      <div
        css={(theme) => ({
          borderRadius: 5,
          textAlign: 'left',
          color:
            assistant || variant === 'light'
              ? theme.palette.primary.main
              : '#fff',
          padding: 12,
          backgroundColor:
            assistant || variant === 'light'
              ? theme.palette.lightGreen
              : theme.palette.primary.main,
        })}
      >
        <Typography css={{ fontSize: 14, b: { fontWeight: 500 } }}>
          {children}
        </Typography>
      </div>
    </div>
  </Fade>
);

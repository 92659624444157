/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';

export const CGUNotice = () => (
  <div
    css={(theme) => ({
      flexShrink: 0,
      padding: 8,
      backgroundColor: theme.palette.white,
    })}
  >
    <Typography variant="caption" color="disabled">
      {'En utilisant ce service vous acceptez les '}
      <a
        href="https://aldebaran.care/cgu/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {"conditions générales d'utilisation"}
      </a>
    </Typography>
  </div>
);

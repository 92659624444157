import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';

import { App } from './App';
import { ThemeProvider } from '@mui/material';
import { theme } from './util/theme';
import config from './config';
import { ErrorBoundary } from './components/ErrorBoundary';

const cache = new InMemoryCache();

const client = new ApolloClient({
  uri: config.kbUrl + config.kbEndpoint,
  cache,
});

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </ApolloProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

serviceWorker.unregister();

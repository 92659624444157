import { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { Practice, Professional } from '../types/global';
import { client } from '../util/client';

export const usePractice = (
  slug: string | undefined,
  options: UseQueryOptions<Practice, AxiosError> = {}
) => {
  const queryClient = useQueryClient();
  return useQuery<Practice, AxiosError>(
    ['practice', slug],
    async () =>
      client(`/practice/public/${slug}`).then((response) => response?.data),
    {
      enabled: !!slug,
      onSuccess: (practice: Practice) => {
        practice.users.forEach((professional: Professional) =>
          queryClient.setQueryData(
            ['professional', professional.slug],
            professional
          )
        );
        options.onSuccess?.(practice);
      },
      ...options,
    }
  );
};

/** @jsxImportSource @emotion/react */
import { FocusEvent, ReactNode } from 'react';

import { Input, InputOnBlurEvent } from './Input';

interface ResponseTextFieldProps {
  value?: string;
  label: string;
  onChange: (value: string) => void;
  multiline?: boolean;
  disabled?: boolean;
  startIcon?: ReactNode;
}

export const ResponseTextField = ({
  value,
  label,
  onChange,
  multiline,
  ...props
}: ResponseTextFieldProps) => (
  <Input
    defaultValue={value}
    placeholder={label}
    onBlur={(e: InputOnBlurEvent) => onChange(e.target?.value)}
    multiline={multiline}
    rows={multiline ? 6 : undefined}
    {...props}
  />
);

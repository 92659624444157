import { useContext, useMemo } from 'react';
import { PersistContext } from '../context/persistContext';

export const usePurgeState = () => {
  const persistor = useContext(PersistContext);
  const purge = useMemo(
    () => (persistor ? persistor.purge : () => null),
    [persistor]
  );
  return purge;
};

/** @jsxImportSource @emotion/react */

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Typography, useTheme } from '@mui/material';

import { ReactComponent as MedicalRecord } from '../assets/MedicalRecord.svg';

import { RootState } from '../types/graph';

import { getToName } from '../util/professional';
import config from '../config';
import { useStatistics } from '../hooks/useStatistics';
import { usePurgeState } from '../hooks/usePurgeState';
import { DemoRequest } from '../components/DemoRequest';

export const Confirmation = () => {
  const theme = useTheme();

  const consultation = useSelector((state: RootState) => state.consultation);
  const recordStatistics = useStatistics();
  const purgeState = usePurgeState();

  useEffect(() => {
    purgeState();
  }, []);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100%',
        backgroundColor: theme.palette.white,
      }}
    >
      <div
        css={{
          flexGrow: 1,
          marginLeft: 24,
          marginRight: 24,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 16,
          color: theme.palette.primary.main,
          backgroundColor: 'none',
        }}
      >
        <MedicalRecord />
        <Typography
          variant="h5"
          sx={{ textAlign: 'center', fontSize: 16, fontWeight: 500 }}
        >
          Vos réponses ont bien été transmises{' '}
          {consultation.professional
            ? getToName(consultation.professional)
            : 'à votre médecin'}
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: 14 }}>
          Les informations fournies par cet assistant ne peuvent se substituer à
          un avis médical réalisé par un professionnel de santé.
        </Typography>
        {config.env.demo && <DemoRequest />}
      </div>
      <Link
        to="/practice"
        onClick={() => {
          recordStatistics({ section: 'REPEAT_QUESTIONNAIRE' });
        }}
        css={{ marginBottom: 24 }}
      >
        Nouveau questionnaire ?
      </Link>
    </div>
  );
};
